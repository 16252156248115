import React from 'react';

import { ConfirmationTypes } from '../beneficiaries/fileUploadPages/file-uploader-stepper/utils/types';
import { CountrySpecificType, SupportedCountries } from './types';

type ConfirmationTypeToCustomValueMapping = {
    [key in ConfirmationTypes]?: CountrySpecificType;
};

export const confirmationTextMapping: ConfirmationTypeToCustomValueMapping = {
    whenIncrementalShiftPeriod: {
        customPerCountry: {
            [SupportedCountries.syr_gos]: (
                <>
                    If your assistance period start date is shifted to the future (possible with the “Incremental (Shift
                    Period)” UAOP option, commit to ensuring your FSP or distribution point makes the assistance
                    available to households as close as possible to the newly indicated start date.
                </>
            ),
        },
    },
};
