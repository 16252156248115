import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { AuthorizationSideMenu as SideMenu, AuthorizationSideMenuProps } from '../utils/SideMenu';
import { AuthState, hasFeatureAccess } from '../login/auth';
import { ActionCreators, UpdateStateListType } from './taskAuthorizations';
import { ManagerPermission } from '../permission-profiles/permission';
import State from '@wfp-root-app/store/state';
import { Outlet, useParams } from 'react-router-dom';
import BreadCrumbs from 'src/utils/BreadCrumbs';
import { AdjustmentsPendingActions } from '../utils/pedingActionsReducer';
import { NavigateHook, useNavigate } from '@wfp-common/hooks/useNavigate';
import { LocationHook, useLocation } from '@wfp-common/hooks/useLocation';

export type AuthorizationTaskType = 'users' | 'beneficiary-support' | 'beneficiary-support-users';

export enum AuthorizationTab {
    ManagerDetails = 'Users',
    PartnersDetails = 'Beneficiary Support',
    PartnersUsersDetails = 'Beneficiary Support Users',
}

const urlPathForTab = {
    [AuthorizationTab.ManagerDetails]: 'users',
    [AuthorizationTab.PartnersDetails]: 'beneficiary-support',
    [AuthorizationTab.PartnersUsersDetails]: 'beneficiary-support-users',
};

interface Props {
    auth: AuthState;
    changeListType: (listType: UpdateStateListType) => void;
    pendingAdjustmentActions: AdjustmentsPendingActions;
    locationToggle: boolean;
    location: LocationHook;
    taskType: string;
    navigate: NavigateHook;
    dispatch: Dispatch<State>;
    hideBeneficiarySupportTab: boolean;
}

type TransactionAuthorizationState = {};

function AuthorizationSideMenu(props: AuthorizationSideMenuProps<string>) {
    return SideMenu(props);
}

class TaskAuthorizationsPage extends React.Component<Props, TransactionAuthorizationState> {
    constructor(props) {
        super(props);
    }

    UNSAFE_componentWillMount() {
        this.props.changeListType(UpdateStateListType.Requested);
    }

    sidemenuElements(): [{ text: any; counter: number }] {
        const { auth, pendingAdjustmentActions, hideBeneficiarySupportTab } = this.props;
        const hasManagersUpdatesRead = hasFeatureAccess(auth, ManagerPermission.managersManagement);
        const hasPartnersRead = hasFeatureAccess(auth, ManagerPermission.partnersRead);
        const tabs: any = [];

        const usersTab = {
            text: AuthorizationTab.ManagerDetails,
            counter:
                pendingAdjustmentActions && pendingAdjustmentActions.users > 0
                    ? Number(pendingAdjustmentActions.users)
                    : 0,
        };

        const beneficiarySupportTab = {
            text: AuthorizationTab.PartnersDetails,
            counter:
                pendingAdjustmentActions && pendingAdjustmentActions.partners > 0
                    ? Number(pendingAdjustmentActions.partners)
                    : 0,
        };
        const beneficiarySupportUsersTab = {
            text: AuthorizationTab.PartnersUsersDetails,
            counter:
                pendingAdjustmentActions && pendingAdjustmentActions.partnerUsers > 0
                    ? Number(pendingAdjustmentActions.partnerUsers)
                    : 0,
        };

        if (hasManagersUpdatesRead) {
            tabs.push(usersTab);
        }

        if (!hideBeneficiarySupportTab && hasPartnersRead) {
            tabs.push(beneficiarySupportTab);
            tabs.push(beneficiarySupportUsersTab);
        }

        return tabs;
    }

    activeMenuTab(): AuthorizationTab {
        const tab = Object.entries(urlPathForTab).find(([, v]) => v === this.props.taskType);

        if (!tab) {
            return;
        }

        this.props.changeListType(UpdateStateListType.Requested);

        return tab[0] as AuthorizationTab;
    }

    renderSidemenu() {
        const tabs = this.sidemenuElements();
        const activeTab = this.activeMenuTab();

        return (
            <div style={{ marginTop: 60 }}>
                <AuthorizationSideMenu
                    activeTab={activeTab}
                    onSelectTab={this.changeActiveTab.bind(this)}
                    tabs={tabs}
                />
            </div>
        );
    }

    changeActiveTab(activeTab) {
        const url = urlPathForTab[activeTab.toString()];
        this.props.navigate(url);
    }

    render() {
        return (
            <div>
                <BreadCrumbs path={'/adjustments'} />

                <main className={'row'}>
                    <div className="col-sm-3">{this.renderSidemenu()}</div>
                    <div className="col-sm-9" style={{ marginBottom: 150 }}>
                        <Outlet />
                    </div>
                    <div className="clearfix" />
                </main>
            </div>
        );
    }
}

function mapStateToProps(state: State) {
    return {
        auth: state.auth,
        pendingAdjustmentActions: state.pendingActions.adjustments,
        locationToggle: state.appConfig.location.isActive,
        hideBeneficiarySupportTab: state.appConfig.hideBeneficiarySupportTab,
    };
}

function mapDispatchToProps(dispatch: Dispatch<State>) {
    return {
        changeListType: bindActionCreators(ActionCreators.changeListType, dispatch),
    };
}

const withHooks = (Component: any) => {
    return (props: any) => {
        const location = useLocation();
        const params = useParams();
        const navigate = useNavigate();
        const dispatch = useDispatch();

        return (
            <Component
                {...props}
                location={location}
                taskType={params.taskType}
                navigate={navigate}
                dispatch={dispatch}
            />
        );
    };
};
export default connect(mapStateToProps, mapDispatchToProps, null)(withHooks(TaskAuthorizationsPage) as any);
