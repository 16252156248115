import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import State from '@wfp-root-app/store/state';

import AsyncTasksListExportView from '../utils/AsyncTasksListExportView';
import { ActionCreators as BeneficiaryActionCreators } from '../beneficiaries/beneficiaries';
import { ActionCreators as AsyncActionCreators, AsyncTask, AsyncTasksTypes } from '../utils/asyncTasks';
import { Filter } from '../utils/FilterTypes';
import { PagedState } from '../utils/paging';
import { RefreshSignaler } from '../utils/refresher';
import { EntitlementCategoriesConfig } from '../app/appConfig';

interface Props {
    exports: PagedState<AsyncTask>;
    entitlementTotalCount?: number;
    entitlementAtLeastTotalCount?: number;
    entitlementsFilters: Array<Filter>;
    downloadFile: (type: string, id: string) => string;
    loadAsyncTasksList: (page: number, limit: number, type: string) => Promise<RefreshSignaler>;
    exportEntitlements: (timezone: string, mapping: Array<{ key; header }>, filters: any) => Promise<any>;
    authorizeTask: (type: string, id: string) => Promise<any>;
    appendAsyncTasksList: (limit: number, type: string) => void;
    managerId: string;
    categories: EntitlementCategoriesConfig;
    timezone: string;
}

interface LocalState {
    selectedColumns: any;
    selectAll: boolean;
}

class DeduplicatedEntitlementExportPage extends React.Component<Props, LocalState> {
    private availableColumns: any = [
        { key: 'id', header: 'BB Account ID' },
        { key: 'organization', header: 'Organization' },
        { key: 'category', header: 'Category' },
        { key: 'amount', header: 'Amount' },
        { key: 'from', header: 'From Date' },
        { key: 'to', header: 'To Date' },
        { key: 'accountCreation', header: 'Account Creation Date' },
        { key: 'deduplicationFlag', header: 'Deduplication Flag' },
        { key: 'deduplicationCategories', header: 'Deduplication Categories' },
        { key: 'entitlementCreation', header: 'Deduplication Date' },
        { key: 'message', header: 'Results' },
        { key: 'refEntitlementOrganization', header: 'Original entitlement - Organization' },
        { key: 'refEntitlementCategory', header: 'Original entitlement - Category' },
        { key: 'refEntitlementAmount', header: 'Original entitlement - Amount' },
        { key: 'refEntitlementLoadDate', header: 'Original entitlement - Load' },
        { key: 'refEntitlementValidFrom', header: 'Original entitlement - Start' },
        { key: 'refEntitlementExpiry', header: 'Original entitlement - End' },
        { key: 'removedByAsyncTaskId', header: 'Task ID - Assistance Removed' },
        { key: 'asyncTaskId', header: 'Task ID - Deduplicated Entitlement' },
        { key: 'refEntitlementRemovalReason', header: 'Reason for assistance removal' },
    ];

    private defaultPageSize = 10;
    private refresher?: RefreshSignaler;

    constructor(props: Props) {
        super(props);
        this.state = {
            selectedColumns: {
                id: true,
                householdId: true,
            },
            selectAll: false,
        };
    }

    async changePageRequested() {
        this.props.appendAsyncTasksList(
            this.defaultPageSize,
            AsyncTasksTypes.EXPORT_BENEFICIARIES_DEDUPLICATED_ENTITLEMENTS
        );
    }

    async UNSAFE_componentWillMount() {
        this.refresher = await this.props.loadAsyncTasksList(
            1,
            this.defaultPageSize,
            AsyncTasksTypes.EXPORT_BENEFICIARIES_DEDUPLICATED_ENTITLEMENTS
        );
    }

    componentWillUnmount() {
        if (this.refresher) {
            this.refresher.stop();
        }
    }

    renderExportFeature() {
        return (
            <div>
                <div className="wfp-form--group">
                    <div className="checkbox" key="all" style={{ marginBottom: 25 }}>
                        <label>
                            <input
                                checked={this.state.selectAll}
                                name="all"
                                onChange={this._handleCheckAll.bind(this)}
                                type="checkbox"
                            />{' '}
                            Select all
                        </label>
                    </div>
                    {this.availableColumns.map((column) => (
                        <div className="checkbox" key={column.key}>
                            <label>
                                <input
                                    checked={this.state.selectedColumns[column.key] || false}
                                    name={column.key}
                                    onChange={this._handleCheck.bind(this)}
                                    type="checkbox"
                                />{' '}
                                {column.header}
                            </label>
                        </div>
                    ))}
                </div>

                <div className="wfp-form--actions">
                    <button className="wfp-btn" type="submit">
                        Generate
                    </button>
                </div>
            </div>
        );
    }

    renderExportFeatureDescription() {
        return (
            <div>
                {this.props.entitlementTotalCount !== null && this.props.entitlementTotalCount !== undefined && (
                    <p>{this.props.entitlementTotalCount.toLocaleString('en-US')} deduplicated entitlements listed.</p>
                )}

                {(this.props.entitlementTotalCount === null || this.props.entitlementTotalCount === undefined) &&
                    this.props.entitlementAtLeastTotalCount !== null &&
                    this.props.entitlementAtLeastTotalCount !== undefined && (
                        <p>
                            At least {this.props.entitlementAtLeastTotalCount.toLocaleString('en-US')} deduplicated
                            entitlements listed.
                        </p>
                    )}
                <p>Please select the information you would like to download per Entitlement:</p>
            </div>
        );
    }

    render() {
        const title = 'Download Historical Deduplication Assistance';

        return (
            <main>
                <nav className="wfp-breadcrumbs">
                    <ol className="breadcrumbs--wrapper">
                        <li className="breadcrumbs--item">
                            <Link className="breadcrumbs--link" to="/home-basic">
                                <span>Home</span>
                            </Link>
                        </li>
                        <li className="breadcrumbs--item">
                            <Link className="breadcrumbs--link" to="/entitlements/download">
                                <span>File Download</span>
                            </Link>
                        </li>
                        <li className="breadcrumbs--item">
                            <span className="breadcrumbs--last">{title}</span>
                        </li>
                    </ol>
                </nav>
                <h3>{title}</h3>
                {this.renderExportFeatureDescription()}
                <form onSubmit={this._exportEntitlements.bind(this)}>{this.renderExportFeature()}</form>
                {this.props.exports && this.props.exports.items.length > 0 && (
                    <div>
                        <AsyncTasksListExportView
                            authorize={this.props.authorizeTask.bind(this)}
                            authorizedManagerId={this.props.managerId}
                            canDownload={true}
                            data={this.props.exports.items}
                            downloadFile={this.props.downloadFile.bind(this)}
                        />
                        <div className="wfp-form--actions">
                            <button
                                className="wfp-btn--primary"
                                disabled={this.props.exports.items.length === this.props.exports.paging.total}
                                onClick={this.changePageRequested.bind(this)}
                                type="button"
                            >
                                More
                            </button>
                        </div>
                    </div>
                )}
            </main>
        );
    }

    _handleCheck(event) {
        const key = event.target.name;
        const value = event.target.checked;

        this.setState((prevState) => {
            const selectedColumns = prevState.selectedColumns;
            selectedColumns[key] = value;
            return { selectedColumns };
        });
    }

    _handleCheckAll(event) {
        const value = event.target.checked;
        const selectedColumns = {};

        this.availableColumns.forEach((column) => {
            selectedColumns[column.key] = value;
        });

        this.setState(() => {
            return { selectedColumns, selectAll: value };
        });
    }

    _exportEntitlements(event) {
        event.preventDefault();
        const mapping = this.availableColumns.filter((column) => this.state.selectedColumns[column.key]);

        return this.props
            .exportEntitlements(this.props.timezone, mapping, this.props.entitlementsFilters)
            .then(() =>
                this.props.loadAsyncTasksList(
                    1,
                    this.defaultPageSize,
                    AsyncTasksTypes.EXPORT_BENEFICIARIES_DEDUPLICATED_ENTITLEMENTS
                )
            );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        exportEntitlements: bindActionCreators(BeneficiaryActionCreators.exportDeduplicatedEntitlements, dispatch),
        loadAsyncTasksList: bindActionCreators(AsyncActionCreators.loadAsyncTasksList, dispatch),
        appendAsyncTasksList: bindActionCreators(AsyncActionCreators.appendAsyncTasksList, dispatch),
        authorizeTask: bindActionCreators(AsyncActionCreators.authorizeAsyncTask, dispatch),
        downloadFile: bindActionCreators(AsyncActionCreators.downloadFile, dispatch),
    };
}

function mapStateToProps(state: State) {
    return {
        entitlementTotalCount:
            state.deduplicatedEntitlements &&
            state.deduplicatedEntitlements.paging &&
            state.deduplicatedEntitlements.paging.total,
        entitlementAtLeastTotalCount:
            state.deduplicatedEntitlements &&
            state.deduplicatedEntitlements.paging &&
            state.deduplicatedEntitlements.paging.totalAtLeast,
        exports:
            state.asyncTasks.asyncTasks[AsyncTasksTypes.EXPORT_BENEFICIARIES_DEDUPLICATED_ENTITLEMENTS] ||
            new PagedState(),
        entitlementsFilters: state.deduplicatedEntitlements && state.deduplicatedEntitlements.filters,
        managerId: state.auth.manager ? state.auth.manager.id : null,
        categories: state.appConfig.entitlementsConfig,
        timezone: state.appConfig.timeZone,
    };
}

export default connect(mapStateToProps, mapDispatchToProps, null)(DeduplicatedEntitlementExportPage as any);
