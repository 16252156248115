import { UaopFlags } from '../../DryRunDialog';
import { getCurrentCountry } from '../../../../utils/countries';
import { uaopFlagDescriptionMapping } from '../../../../country/uaopFlagDescriptionMapping';
import { MetaCategories } from '../../../../country/types';

export const getTextByMetaCategoryAndFlag = (metaCategory: MetaCategories, uaopFlag: UaopFlags) => {
    const text = uaopFlagDescriptionMapping[uaopFlag];
    const customTextPerCountry = text?.customPerCountry?.[getCurrentCountry()];
    const customTextPerMetaCategory = text?.customPerMetaCategoryAndCountry?.[metaCategory]?.[getCurrentCountry()];

    if (!text || (!text.default && !customTextPerMetaCategory && !customTextPerCountry)) {
        throw new Error(`Missing text mapping for uaop flag: ${uaopFlag}`);
    }

    return customTextPerMetaCategory || customTextPerCountry || text.default;
};
