import React from 'react';
import Switch from 'rc-switch';
import BreadCrumbs from '../utils/BreadCrumbs';
import { Input } from '../utils/inputs';
import { useDocumentUpload } from './utils/useDocumentUpload';

export function UploadDocumentsForm() {
    const { handleChange, handleSubmit, values, uploading, setIsFileUploadChose, isFileUploadChose } =
        useDocumentUpload();
    return (
        <>
            <BreadCrumbs path="/documents/create" />
            <h3>Add Document</h3>
            <form className="wfp-form BDS-upload-documents-form-container" onSubmit={handleSubmit}>
                <label htmlFor="document">
                    <span>Document Upload</span>
                    <div>
                        <Switch
                            checked={isFileUploadChose}
                            onChange={() => {
                                setIsFileUploadChose(!isFileUploadChose);
                            }}
                        />
                    </div>
                </label>
                {isFileUploadChose ? (
                    <label htmlFor="document">
                        <span>File</span>
                        <input
                            accept="image/png, image/jpeg, application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .xlsx, text/csv, .csv, .docx"
                            id="document"
                            name="document"
                            onChange={handleChange}
                            type="file"
                        />
                    </label>
                ) : (
                    <label htmlFor="document">
                        <span>File Link</span>
                        <Input name="externalLink" onChange={handleChange} value={values.externalLink} />
                    </label>
                )}
                <label>
                    <span>Section</span>
                    <Input name="section" onChange={handleChange} value={values.section} />
                </label>
                <label>
                    <span>File Name</span>
                    <Input name="fileName" onChange={handleChange} value={values.fileName} />
                </label>
                {uploading && (
                    <div className="progress">
                        <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" />
                    </div>
                )}
                <button className="wfp-btn--primary" disabled={uploading} type="submit">
                    {isFileUploadChose ? 'Upload' : 'Create Document'}
                </button>
            </form>
        </>
    );
}
