// TODO -> check used places and remove unused code
export enum countries {
    BANGLADESH = 'bangladesh',
    JORDAN = 'jordan',
}

export const getCurrentCountry = () => {
    return process.env.COUNTRY || 'ukraine';
};

export const getCountryDisplayName = (country: string) => {
    if (country.includes('-')) {
        const parts = country.split('-');
        return parts[1].toUpperCase();
    } else {
        return country.toUpperCase();
    }
};
