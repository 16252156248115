import { useSelector } from 'react-redux';
import State from '@wfp-root-app/store/state';
import React, { FC, useCallback } from 'react';
import { dispatch } from '@wfp-root-app/store/store';

import { UploadStatuses } from '../../../../utils/import';
import { ActionCreators as BeneficiaryActionCreators, UploadTaskState } from '../../../beneficiaries';
import { FileUpload } from '../../common/components/FileUpload';
import { useFileUploadContext } from '../../common/context/FileUploadContext';
import { mapRemovalReasonNameToDisplayName } from '../utils';
import { RemovalReason } from './ReasonSelectStep';

export interface FileUploadStepProps {
    selectedReason: RemovalReason;
}

export const FileUploadStep: FC<FileUploadStepProps> = ({ selectedReason }) => {
    const mapping = useSelector((state: State) => state.appConfig.removeEntitlementsImportMapping);
    const {
        selectedFile,
        showUploadStatusWidget,
        setSelectedFile,
        setUploadTaskStatus,
        setRefreshHandle,
        setShowUploadStatusWidget,
    } = useFileUploadContext();
    const primaryIdentifierType = useSelector((state: State) => state.appConfig.beneficiaryPrimaryIdentifierType);

    const getUploadModel = useCallback(
        (dryRun: boolean, file: File) => ({
            data: file,
            schedule: !dryRun,
            dryRun: dryRun,
            mapping,
            startIndex: 2,
        }),
        [mapping]
    );

    const handleFileSelection = (files: FileList) => {
        if (files.length) {
            setSelectedFile(files[0]);

            const model = getUploadModel(true, files[0]);
            setUploadTaskStatus(new UploadTaskState(UploadStatuses.pending));
            dispatch(BeneficiaryActionCreators.importRemoveEntitlements(model));
            setRefreshHandle(true);
            setShowUploadStatusWidget(true);
        }
    };

    return (
        <FileUpload
            fileUploadContext={
                <div>
                    <span className={'font-weight-bold'}>Removal reason: </span>
                    <span>{mapRemovalReasonNameToDisplayName(selectedReason, primaryIdentifierType)}</span>
                </div>
            }
            handleFileSelection={handleFileSelection}
            selectedFile={selectedFile}
            showUploadStatusWidget={showUploadStatusWidget}
        />
    );
};
