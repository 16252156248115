import React from 'react';

export enum MetaCategories {
    CWG = 'CWG',
    NFI = 'NFI',
    FSL = 'FSL',
}

export enum SupportedCountries {
    ukraine = 'ukraine',
    syr_nws = 'syr-nws',
    syr_gos = 'syr-gos',
}

export type CountrySpecificType<T = React.ReactNode> = {
    customPerCountry?: { [key in SupportedCountries]?: T };
};

export type MetaCategoryAndCountrySpecificType<T = React.ReactNode> = {
    customPerMetaCategoryAndCountry?: {
        [key in MetaCategories]?: {
            [country in SupportedCountries]?: T;
        };
    };
};

export type DefaultValueType<T = React.ReactNode> = {
    default: T;
};
