import { UaopFlags } from '../beneficiaries/fileUploadPages/DryRunDialog';
import { CountrySpecificType, DefaultValueType, SupportedCountries } from './types';

type ConfirmationTypeToCustomValueMapping = {
    [key in UaopFlags]: CountrySpecificType<string> & DefaultValueType<string>;
};

export const customUaopFileExampleMapping: ConfirmationTypeToCustomValueMapping = {
    [UaopFlags.IncrementalAnyAmount]: {
        customPerCountry: {
            [SupportedCountries.syr_gos]:
                'https://wfp-public.s3.eu-central-1.amazonaws.com/UAOP_incremental_any_amount_syr_gos.html',
        },
        default: 'https://wfp-public.s3.eu-central-1.amazonaws.com/UAOP_incremental_any_amount.html',
    },
    [UaopFlags.IncrementalShiftPeriod]: {
        default: 'https://wfp-public.s3.eu-central-1.amazonaws.com/UAOP_incremental_shift_period.html',
    },
    [UaopFlags.IncrementalDeltaAmountYesOption]: {
        default: 'https://wfp-public.s3.eu-central-1.amazonaws.com/UAOP_incremental_delta_amount_yes_flag.html',
    },
    [UaopFlags.IncrementalDeltaAmountNoOption]: {
        default: 'https://wfp-public.s3.eu-central-1.amazonaws.com/UAOP_incremental_delta_amount_no_flag.html',
    },
    [UaopFlags.IncrementalAnyAmountWithAllowedOverlap]: {
        default: 'https://wfp-public.s3.eu-central-1.amazonaws.com/UAOP_incremental_intended_assistance_overlap.html',
    },
    [UaopFlags.TotalDeltaAmount]: {
        default: 'https://wfp-public.s3.eu-central-1.amazonaws.com/UAOP_total_delta_amount.html',
    },
    [UaopFlags.IncrementalDeltaAmountV2]: {
        customPerCountry: {
            [SupportedCountries.syr_gos]:
                'https://wfp-public.s3.eu-central-1.amazonaws.com/UAOP_incremental_delta_amount_v2_syr_gos.html',
        },
        default: '',
    },
    [UaopFlags.TotalDeltaAmountV2]: {
        default: 'https://wfp-public.s3.eu-central-1.amazonaws.com/UAOP_total_delta_amount_v2.html',
    },
    [UaopFlags.IncrementalShiftPeriodV2]: {
        default: 'https://wfp-public.s3.eu-central-1.amazonaws.com/UAOP_incremental_shift_period_v2.html',
    },
    [UaopFlags.TotalAnyAmount]: {
        default: 'https://wfp-public.s3.eu-central-1.amazonaws.com/UAOP_total_any_amount.html',
    },
    [UaopFlags.NoneHistorical]: {
        default: '',
    },
    [UaopFlags.NoneIntendedAssistanceOverlap]: {
        default: '',
    },
};
