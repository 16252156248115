import { BeneficiaryPrimaryIdentifierToShow, BeneficiaryPrimaryIdentifierType } from '@wfp-root-app/appConfig';

import { RemovalReason } from './steps/ReasonSelectStep';

export const mapRemovalReasonNameToDisplayName = (
    enumValue: RemovalReason,
    beneficiaryIdentifierType: BeneficiaryPrimaryIdentifierType
) => {
    switch (enumValue) {
        case RemovalReason.ChangeInHoHFinancialData:
            return 'Change in HoH financial data (cash)';
        case RemovalReason.ReversalOfPayment:
            return 'Reversal of payment (cash)';
        case RemovalReason.ErrorInOriginalIntendedAssistanceFileLoadedToBB:
            return 'Error in original intended assistance file loaded to BB';
        case RemovalReason.IncorrectUserId:
            return `Incorrect ${BeneficiaryPrimaryIdentifierToShow[beneficiaryIdentifierType]}`;
        case RemovalReason.DistributionCancelled:
            return 'Distribution cancelled (in-kind)';
        case RemovalReason.Other:
            return 'Other';
    }
};
