import React, { createContext, FC, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import State from '@wfp-root-app/store/state';

import { UploadTaskState } from '../../../beneficiaries';
import { UploadStatuses } from '../../../../utils/import';
import { getAsyncTask } from '../../../../apiClient';
import { useInterval } from '../../../../utils/hooks/IntervalHook';

interface FileUploadState {
    selectedFile: File | null;
    uploadTaskStatus: UploadTaskState;
    refreshHandle: boolean | null;
    showUploadStatusWidget: boolean;
}

const initialState: FileUploadState = {
    selectedFile: null,
    uploadTaskStatus: new UploadTaskState(UploadStatuses.pending),
    refreshHandle: null,
    showUploadStatusWidget: false,
};

type FileUploadContextType = FileUploadState & {
    setSelectedFile: (selectedFile: File) => void;
    setUploadTaskStatus: (uploadTaskStatus: UploadTaskState) => void;
    setRefreshHandle: (refreshHandle: boolean) => void;
    setShowUploadStatusWidget: (status: boolean) => void;
};

export const FileUploadProvider: FC = ({ children }) => {
    const [selectedFile, setSelectedFile] = useState<File>(null);
    const [uploadTaskStatus, setUploadTaskStatus] = useState(new UploadTaskState(UploadStatuses.pending));
    const [refreshHandle, setRefreshHandle] = useState<boolean>(null);
    const [showUploadStatusWidget, setShowUploadStatusWidget] = useState(false);
    const uploadTaskState = useSelector((state: State) => state.uploadTaskState);

    const loadTaskState = async () => {
        if (uploadTaskState.error) {
            setUploadTaskStatus(new UploadTaskState(UploadStatuses.finished, null, uploadTaskState.error));
        } else if (uploadTaskState.task) {
            const response = await getAsyncTask(uploadTaskState.task.type, uploadTaskState.task.id);
            const taskStatus =
                response.task.finishedAt && response.task.children && response.task.children.length > 0
                    ? UploadStatuses.finished
                    : UploadStatuses.pending;
            const taskState = new UploadTaskState(taskStatus, response.task);
            setUploadTaskStatus(taskState);
            if (taskStatus !== UploadStatuses.pending) {
                setRefreshHandle(false);
            }
        }
    };

    useInterval(loadTaskState, !!refreshHandle ? 2000 : null);

    return (
        <FileUploadContext.Provider
            value={{
                selectedFile,
                setSelectedFile,
                refreshHandle,
                setRefreshHandle,
                uploadTaskStatus,
                setUploadTaskStatus,
                showUploadStatusWidget,
                setShowUploadStatusWidget,
            }}
        >
            {children}
        </FileUploadContext.Provider>
    );
};

const FileUploadContext = createContext<FileUploadState | any>(initialState);

export const useFileUploadContext = () => {
    const context = useContext<FileUploadContextType>(FileUploadContext);

    if (!context) throw new Error('FileUploadContextProvider should be wrapped.');

    return context;
};
