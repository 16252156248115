import React from 'react';
import FeatureButton from '../utils/FeatureButton';
import State from '@wfp-root-app/store/state';
import { connect } from 'react-redux';
import { AuthState } from '../login/auth';
import PartnersList from './PartnersList';
import { bindActionCreators } from 'redux';
import { ActionCreators } from './partnersActions';
import { ManagerPermission } from '../permission-profiles/permission';
import { BENEFICIARY_SUPPORT_PANEL_URL } from './utils/constants';
import BreadCrumbs from 'src/utils/BreadCrumbs';

interface DispatchProps {
    authorizePartner: (id: string) => void | any;
    resetPassword: (id: string) => void | any;
    cancelPartner: (partnerId: string) => void | any;
    hideBeneficiarySupportTab: boolean;
}

interface StateProps {
    auth: AuthState;
}

type Props = DispatchProps & StateProps;

class PartnersPage extends React.Component<Props, {}> {
    constructor(props) {
        super(props);
    }

    render() {
        if (this.props.hideBeneficiarySupportTab) {
            return <p>No access</p>;
        }

        return (
            <main>
                <BreadCrumbs path={'/beneficiary-support-panel'} />
                <div className="row">
                    <h3 className="col-sm-5">Beneficiary Support Panel</h3>
                    <div className="col-sm-7 tr">
                        <FeatureButton
                            linkTo={`${BENEFICIARY_SUPPORT_PANEL_URL}/new`}
                            manager={this.props.auth.manager}
                            restrictedToFeatures={[ManagerPermission.partnerPark]}
                            title="Add Organization"
                        />
                    </div>
                </div>
                <PartnersList
                    auth={this.props.auth}
                    authorize={this.props.authorizePartner.bind(this)}
                    handleCancel={this.props.cancelPartner}
                    resetPassword={this.props.resetPassword.bind(this)}
                />
            </main>
        );
    }
}

function mapStateToProps(state: State) {
    return {
        auth: state.auth,
        hideBeneficiarySupportTab: state.appConfig.hideBeneficiarySupportTab,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        cancelPartner: bindActionCreators(ActionCreators.cancelPartner, dispatch),
        authorizePartner: bindActionCreators(ActionCreators.authorizePartner, dispatch),
        resetPassword: bindActionCreators(ActionCreators.resetPassword, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PartnersPage);
