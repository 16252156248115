import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ActionCreators, AssistanceListLabels, AssistanceListType } from './assistance';
import EntitlementsPage from './EntitlementsPage';
import DeduplicatedEntitlementsPage from './DeduplicatedEntitlementsPage';
import State from '@wfp-root-app/store/state';
import { RowMenu } from '../utils/RowMenu';
import TaxIdMappingExportPage from './TaxIdMappingExportPage';
import { DeduplicationStatistics } from '../statistics/deduplicationStatistics/DeduplicationStatistics';
import { selectAuth } from '@wfp-root-app/store/store';

const AssistanceContainer: React.FC = () => {
    const activeTab = useSelector((state: State) => state.assistance.activeTab);
    const manager = useSelector((state: State) => state.auth.manager);
    const dispatch = useDispatch();
    const auth = useSelector(selectAuth);
    const beneficiaryPrimaryIdentifierType = useSelector(
        (state: State) => state.appConfig.beneficiaryPrimaryIdentifierType
    );

    const tabDisplayNames = {
        ...AssistanceListLabels(beneficiaryPrimaryIdentifierType),
    };

    const onSelectTab = (tab: AssistanceListType) => {
        dispatch(ActionCreators.changeTab(tab));
    };

    const renderAssistanceList = () => {
        switch (activeTab) {
            case AssistanceListType.AssistanceProvided:
                return <EntitlementsPage />;
            case AssistanceListType.AssistanceDeduplicated:
                return <DeduplicatedEntitlementsPage />;
            case AssistanceListType.CaseloadOverlap:
                return <DeduplicationStatistics auth={auth} />;
            case AssistanceListType.BBIdMapping:
                return manager?.agency !== 'ALL' ? <TaxIdMappingExportPage /> : null;
            default:
                return null;
        }
    };
    const renderTabs = () => {
        if (manager?.agency !== 'ALL') {
            return [
                AssistanceListType.AssistanceProvided,
                AssistanceListType.AssistanceDeduplicated,
                AssistanceListType.BBIdMapping,
                AssistanceListType.CaseloadOverlap,
            ];
        }

        return [
            AssistanceListType.AssistanceProvided,
            AssistanceListType.AssistanceDeduplicated,
            AssistanceListType.CaseloadOverlap,
        ];
    };

    return (
        <>
            <RowMenu
                activeTab={activeTab}
                onSelectTab={onSelectTab}
                tabs={renderTabs()}
                tabDisplayNames={tabDisplayNames}
            />
            {renderAssistanceList()}
        </>
    );
};

AssistanceContainer.displayName = 'AssistanceContainer';

export { AssistanceContainer };
