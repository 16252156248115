import { ChangeEvent, FormEvent, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createDocumentWithExternalLink, uploadDocument } from '../../apiClient';
import { errorAction } from '../../utils/notifications';
import { useToggle } from '../../utils/useToggle';
import { useNavigate } from '@wfp-common/hooks/useNavigate';
import { IsNotEmpty, IsString, IsUrl, validateOrReject } from 'class-validator';

export class ExternalLinkClassValidator {
    @IsString()
    @IsNotEmpty()
    @IsUrl({ protocols: ['http', 'https'], require_valid_protocol: true })
    externalLink: string;
}

interface ReturnObject {
    values: {
        section: string;
        file: File;
        fileName: string;
        externalLink: string;
    };
    uploading: boolean;

    handleChange(event: ChangeEvent<HTMLInputElement>): void;

    handleSubmit(event: FormEvent<HTMLFormElement>): void;

    isFileUploadChose: boolean;

    setIsFileUploadChose: (value: boolean) => void;
}

export function useDocumentUpload(): ReturnObject {
    const navigate = useNavigate();
    const [values, setValues] = useState({
        section: '',
        file: null,
        fileName: '',
        externalLink: '',
    });
    const [uploading, toggleUploading] = useToggle();
    const [isFileUploadChose, setIsFileUploadChose] = useState(true);
    const dispatch = useDispatch();
    const handleChange = useCallback((event) => {
        event.persist();
        if (event.target.type === 'file') {
            return setValues((state) => {
                const isChanged = Boolean(state.fileName && state.fileName !== state.file?.name);
                const fileName = isChanged ? state.fileName : event.target.files[0].name;
                return {
                    ...state,
                    file: event.target.files[0],
                    fileName,
                };
            });
        }
        setValues((state) => ({ ...state, [event.target.name]: event.target.value }));
    }, []);
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!values.fileName) {
            dispatch(errorAction('File name can not be empty'));
            return;
        }

        if (isFileUploadChose && !values.file) {
            dispatch(errorAction('There is no file to upload'));
            return;
        }

        if (!isFileUploadChose) {
            const linkValidator = new ExternalLinkClassValidator();
            linkValidator.externalLink = values.externalLink;

            try {
                await validateOrReject(linkValidator);
            } catch (e) {
                dispatch(errorAction('File link needs to be valid URL'));
                return;
            }
        }

        try {
            if (isFileUploadChose) {
                toggleUploading(true);
                await uploadDocument(values);
                toggleUploading();
            } else {
                await createDocumentWithExternalLink(values);
            }
            navigate('/documents');
        } catch (error) {
            toggleUploading(false);
            dispatch(errorAction('Could not upload file'));
        }
    };

    return {
        values,
        handleChange,
        handleSubmit,
        uploading,
        isFileUploadChose,
        setIsFileUploadChose,
    };
}
