import React from 'react';

import { AsyncTask, AsyncTasksTypes } from '../../utils/asyncTasks';
import PagingComponent, { PagedState } from '../../utils/paging';
import AsyncTasksListImportView from '../../utils/AsyncTasksListImportView';
import { RefreshSignaler } from '../../utils/refresher';
import { UploadStatusWidget } from '../../utils/import';
import { UploadTaskState } from '../beneficiaries';
import DryRunDialog from './DryRunDialog';
import { Input } from '../../utils/inputs';
import { DownloadStaticFileButton } from '../../utils/DownloadStaticFileButton';
import { SPREADSHEET_EXTENSIONS } from '../../constants/file-upload-extensions.constant';
import { StepperRoot } from './file-uploader-stepper/StepperRoot';
import BreadCrumbs from '../../utils/BreadCrumbs';
import { AssistanceRemovalStepperRoot } from './assistanceRemoval/AssistanceRemovalStepperRoot';
import { asyncTasksWithStepper } from './fileUpload';

export interface ListViewProps {
    data: Array<AsyncTask>;
    canAuthorizeTask?: boolean;
    authorizedManagerId: string;
    authorize: (type: string, id: string) => Promise<void>;
    downloadFile: (type: string, id: string) => string;
    canDownload: boolean;
}

export interface AsyncTasksPageStateProps {
    authorizedManagerId?: string;
    name: string;
    type?: string;
    uploadDescription: string;
    exampleLinkTitle: string;
    exampleUploadFile: any;
    tasks?: PagedState<AsyncTask>;
    canPark: boolean;
    canPost: boolean;
    canDownload?: boolean;
    uploadTaskState: UploadTaskState;
    shouldRunDryRun?: boolean;
    shouldShowInlineWidget?: boolean;

    clearUploadTask?(): void;
}

export interface AsyncTasksPageDispatchProps {
    downloadFile: (type: string, id: string) => void;
    loadAsyncTasksList: (page: number, limit: number) => Promise<RefreshSignaler>;
    uploadFile: (
        data: File,
        dryRun?: boolean,
        mapping?: Record<number, string>,
        dryRunAsyncTaskId?: string,
        additionalData?: Record<string, any>
    ) => Promise<void>;
    authorizeTask: (type: string, id: string) => void;
    listView?: (props: ListViewProps) => any;
    mapping?: Record<number, string>;

    clearUploadTask?(): void;
}

type AsyncTasksPageProps = AsyncTasksPageStateProps & AsyncTasksPageDispatchProps;

interface AsyncTasksPageState {
    isDryRun: boolean;
    file?: File;
    showStepperDialog: boolean;
}

export default class AsyncTasksPage extends React.Component<AsyncTasksPageProps, AsyncTasksPageState> {
    static defaultProps = {
        listView: AsyncTasksListImportView,
        canDownload: true,
        shouldRunDryRun: false,
    };
    private defaultPageSize = 25;
    private refresher?: RefreshSignaler;

    constructor(props: AsyncTasksPageProps) {
        super(props);

        this.defaultPageSize = asyncTasksWithStepper.includes(props.type) ? 10 : 25;

        this.state = {
            isDryRun: props.shouldRunDryRun,
            showStepperDialog: false,
        };
    }

    async UNSAFE_componentWillMount() {
        this.refresher = await this.props.loadAsyncTasksList(1, this.defaultPageSize);
    }

    componentWillUnmount() {
        if (this.refresher) {
            this.refresher.stop();
        }
        this.props.clearUploadTask?.();
    }

    UNSAFE_componentWillReceiveProps(nextProps: AsyncTasksPageProps) {
        if (!this.state.file) {
            this.setState({
                isDryRun: nextProps.shouldRunDryRun,
            });
        }
    }

    render() {
        const { canPark, canPost, canDownload, tasks, authorizedManagerId } = this.props;

        return (
            <main>
                <BreadCrumbs path={`/${this.props.name}`} />

                {/*<h3>{this.props.name}</h3>*/}

                {canPark && this.renderUploadFeature()}

                {this.props.tasks && (
                    <div>
                        <this.props.listView
                            authorize={this.props.authorizeTask.bind(this)}
                            authorizedManagerId={authorizedManagerId}
                            canAuthorizeTask={canPost}
                            canDownload={canDownload}
                            data={tasks.items}
                            downloadFile={this.props.downloadFile.bind(this)}
                        />
                        <PagingComponent onPageChanged={this.changePageRequested.bind(this)} paging={tasks.paging} />
                    </div>
                )}
            </main>
        );
    }

    private async changePageRequested(newPage) {
        this.refresher = await this.props.loadAsyncTasksList(newPage, this.defaultPageSize);
    }

    private upload = async (event, dryRun: boolean) => {
        const file = event.target.files[0];
        if (file) {
            this.setState({ file });
            event.target.value = '';
            await this.props.uploadFile(file, dryRun, this.props.mapping);

            if (!dryRun) {
                this.refresher = await this.props.loadAsyncTasksList(1, this.defaultPageSize);
            }
        }
    };

    private onDryRunCancel = () => {
        this.setState({ file: undefined });
    };

    private onConfirmDryRun = async (dryRunAsyncTaskId: string, additionalData: Record<string, any> = {}) => {
        this.setState({ isDryRun: false });
        await this.props.uploadFile(this.state.file, false, this.props.mapping, dryRunAsyncTaskId, additionalData);
        this.refresher = await this.props.loadAsyncTasksList(1, this.defaultPageSize);
        this.setState({ file: undefined });
    };

    private renderUploadFeature() {
        const {
            name,
            uploadDescription,
            exampleUploadFile,
            exampleLinkTitle,
            uploadTaskState,
            shouldRunDryRun,
            shouldShowInlineWidget,
            type,
        } = this.props;
        const { isDryRun, file, showStepperDialog } = this.state;

        const uploadButton = () => {
            if (asyncTasksWithStepper.includes(type)) {
                return (
                    <button
                        className="wfp-btn replaced-input-file"
                        onClick={() =>
                            this.setState({
                                showStepperDialog: true,
                            })
                        }
                        type="button"
                    >
                        <span>Upload</span>
                    </button>
                );
            } else {
                return (
                    <button className="wfp-btn replaced-input-file" type="button">
                        <span>Upload</span>
                        <Input
                            accept={SPREADSHEET_EXTENSIONS}
                            name="data"
                            onChange={(event) => this.upload(event, shouldRunDryRun)}
                            type="file"
                        />
                    </button>
                );
            }
        };

        return (
            <div>
                <span style={{ display: 'block' }}>{uploadDescription}</span>
                <span>
                    Please ensure that you have exported this list with the correct structure.{' '}
                    <DownloadStaticFileButton filename={exampleUploadFile}>{exampleLinkTitle}</DownloadStaticFileButton>
                </span>

                <div className="wfp-form--actions">
                    {uploadButton()}

                    {showStepperDialog && type === AsyncTasksTypes.IMPORT_BENEFICIARIES && (
                        <StepperRoot onClose={() => this.setState({ showStepperDialog: false })} />
                    )}

                    {showStepperDialog && type === AsyncTasksTypes.UPLOAD_REMOVE_ENTITLEMENTS && (
                        <AssistanceRemovalStepperRoot onClose={() => this.setState({ showStepperDialog: false })} />
                    )}

                    {!asyncTasksWithStepper.includes(type) && isDryRun && !!file && !showStepperDialog && (
                        <DryRunDialog
                            asyncTask={this.props.uploadTaskState.task}
                            downloadFile={this.props.downloadFile}
                            onCancel={this.onDryRunCancel}
                            onConfirm={this.onConfirmDryRun}
                            taskStateError={this.props.uploadTaskState.error}
                            title={name}
                            type={type}
                        />
                    )}

                    {shouldShowInlineWidget && !isDryRun && (
                        <UploadStatusWidget
                            importError={uploadTaskState.error}
                            importStatus={uploadTaskState.status}
                            importTask={uploadTaskState.task}
                        />
                    )}
                </div>
            </div>
        );
    }
}
