import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import State from '@wfp-root-app/store/state';

import { CheckBoxContainer } from '../utils/utils';
import { UaopFlags } from '../../DryRunDialog';
import { useStepperContext } from '../StepperContext';

interface HouseholdExclusionStepProps {
    selectedUaop: UaopFlags;
    selectedAssistanceCategory: string;
    setDeduplicationCategories: (categories: ((prevCategories) => string[]) | string[]) => void;
    deduplicationCategories: string[];
    categoriesToSelect: string[];
    recommendedCategoryForSharedDeduplication?: string;
}

const AdditionalCategoriesStep: FC<HouseholdExclusionStepProps> = (props) => {
    const { disableNextStep, enableNextStep } = useStepperContext();
    const { categoriesToCategoriesFullNames } = useSelector((state: State) => state.appConfig.entitlementsConfig);
    const [noneSelected, setNoneSelected] = useState(false);

    useEffect(() => {
        props.deduplicationCategories.length > 0 || noneSelected ? enableNextStep() : disableNextStep();
    }, [props.deduplicationCategories, noneSelected]);

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value, checked } = event.target;
        setNoneSelected(false);

        if (checked) {
            props.setDeduplicationCategories((prevCategories) => [...prevCategories, value]);
        } else {
            props.setDeduplicationCategories((prevCategories) =>
                prevCategories.filter((category) => category !== value)
            );
        }
    };
    const handleNoneCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target;

        if (checked) {
            setNoneSelected(true);
            props.setDeduplicationCategories([]);
        } else {
            setNoneSelected(false);
        }
    };

    return (
        <div className={'wfp-assisted-exclusion-step'}>
            <h6
                style={{
                    marginTop: '32px',
                }}
            >
                Do you also want to exclude households who have been assisted in any of the following assistance
                categories?
                <br />
                Select all that apply:
            </h6>
            <div>
                {props.categoriesToSelect.map((category) => {
                    return (
                        <CheckBoxContainer key={category} marginTop="0px">
                            <label>
                                <input
                                    checked={props.deduplicationCategories.includes(category)}
                                    className={'mr-2'}
                                    // disabled={p}
                                    name="validate-category"
                                    onChange={handleCheckboxChange}
                                    type="checkbox"
                                    value={category}
                                />{' '}
                                {categoriesToCategoriesFullNames[category]} ({category}){' '}
                                {category === props.recommendedCategoryForSharedDeduplication ? '(Recommended)' : ''}
                            </label>
                        </CheckBoxContainer>
                    );
                })}
                <CheckBoxContainer marginTop="0px">
                    <label>
                        <input
                            checked={noneSelected}
                            className={'mr-2'}
                            name="validate-category"
                            onChange={handleNoneCheckboxChange}
                            type="checkbox"
                            value="none"
                        />{' '}
                        None of the above
                    </label>
                </CheckBoxContainer>
            </div>
        </div>
    );
};

AdditionalCategoriesStep.displayName = 'AdditionalCategoriesStep';

export { AdditionalCategoriesStep };
