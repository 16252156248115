import classNames from 'classnames';
import * as React from 'react';
import { FC, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { CloudUploadIcon } from '../../../../icons/CloudUploadIcon.svg';
import { UploadStatusWidget } from '../../../../utils/import';
import { ActionCreators as AsyncActionCreators } from '../../../../utils/asyncTasks';
import { useFileUploadContext } from '../context/FileUploadContext';

export interface FileUploadProps {
    handleFileSelection: (files: FileList) => void;
    selectedFile: File;
    showUploadStatusWidget: boolean;
    fileUploadContext?: React.ReactElement;
}

export const FileUpload: FC<FileUploadProps> = ({
    handleFileSelection,
    selectedFile,
    showUploadStatusWidget,
    fileUploadContext,
}) => {
    const [dragActive, setDragActive] = useState(false);
    const fileInputRef = useRef(null);
    const dispatch = useDispatch();
    const { uploadTaskStatus } = useFileUploadContext();

    return (
        <div className={'wfp-upload-step'}>
            {!selectedFile && (
                <div
                    className={classNames('wfp-upload-step__drag-drop-area text-secondary bg-light', {
                        'drag-active': dragActive,
                    })}
                    onClick={() => {
                        fileInputRef.current.click();
                    }}
                    onDragEnter={(event) => {
                        if (!dragActive) {
                            setDragActive(true);
                        }
                    }}
                    onDragLeave={(event) => {
                        if (dragActive) {
                            setDragActive(false);
                        }
                    }}
                    onDragOver={(event) => {
                        event.preventDefault();
                    }}
                    onDragStart={(event) => {
                        if (!dragActive) {
                            setDragActive(true);
                        }
                        event.preventDefault();
                    }}
                    onDrop={(event) => {
                        event.preventDefault();
                        setDragActive(false);
                        const dt = event.dataTransfer;

                        handleFileSelection(dt.files);
                    }}
                >
                    <span className={'drag-drop-icon'}>
                        <CloudUploadIcon />
                    </span>
                    <h4 className={'text-center'} style={{ width: '360px' }}>
                        Drag and Drop File or Click to Choose
                    </h4>
                    <small className={'mt-2'}>Your file is going to be validated right after its attach</small>
                    <input
                        hidden={true}
                        onChange={(event) => {
                            const files = event.target.files;

                            handleFileSelection(files);
                        }}
                        ref={fileInputRef}
                        type="file"
                    />
                </div>
            )}

            <div className={'wfp-upload-step__summary mt-3'}>
                {fileUploadContext}
                <div>
                    <span className={'font-weight-bold'}>Enclosed file: </span>
                    <span>{selectedFile?.name || 'no file'}</span>
                </div>

                {selectedFile && showUploadStatusWidget && (
                    <UploadStatusWidget
                        downloadFile={(type, id) => {
                            dispatch(AsyncActionCreators.downloadFile(type, id));
                        }}
                        importError={uploadTaskStatus.error}
                        importStatus={uploadTaskStatus.status}
                        importTask={uploadTaskStatus.task}
                    />
                )}
            </div>
        </div>
    );
};

FileUpload.displayName = 'FileUpload';
