import React, { FC, useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import State from '@wfp-root-app/store/state';

import { StepContent, StepHeader } from '../common/types';
import { ReasonSelectStep, RemovalReason } from './steps/ReasonSelectStep';
import { FileUploadStep } from './steps/FileUploadStep';
import { ModalBackground } from '../file-uploader-stepper/utils/utils';
import { ClearIcon } from '../../../icons/Clear.svg';
import { ActionCreators as BeneficiaryActionCreators, UploadTaskState } from '../../beneficiaries';
import { UploadStatuses } from '../../../utils/import';
import { shouldBlockRunImportButton } from '../common/utils';
import { ActionCreators as AsyncActionCreators, AsyncTasksTypes } from '../../../utils/asyncTasks';
import { useStepperContext } from '../file-uploader-stepper/StepperContext';
import { useFileUploadContext } from '../common/context/FileUploadContext';

export interface AssistanceRemovalStepperProps {
    onClose: () => void;
}

export const AssistanceRemovalStepper: FC<AssistanceRemovalStepperProps> = ({ onClose }) => {
    const { selectedFile, setSelectedFile, uploadTaskStatus, setUploadTaskStatus, setRefreshHandle } =
        useFileUploadContext();
    const [selectedReason, setSelectedReason] = useState<RemovalReason>(null);
    const [currentStep, setCurrentStep] = useState(0);
    const dispatch = useDispatch();
    const { isNextStepDisable, enableNextStep } = useStepperContext();
    const mapping = useSelector((state: State) => state.appConfig.removeEntitlementsImportMapping);

    const stepHeaders = useMemo<StepHeader[]>(() => {
        return [
            {
                label: 'Select removal reason',
                enable: true,
                skip: false,
            },
            {
                label: 'File upload',
                enable: true,
                skip: false,
            },
        ].map((header, index) => ({ ...header, id: index }));
    }, []);

    const stepContents: StepContent[] = [
        {
            id: 0,
            content: <ReasonSelectStep selectedReason={selectedReason} setSelectedReason={setSelectedReason} />,
        },
        {
            id: 1,
            content: <FileUploadStep selectedReason={selectedReason} />,
        },
    ];

    useEffect(() => {
        if (selectedReason) {
            enableNextStep();
        }
    }, [selectedReason]);

    const onPrevStep = () => {
        enableNextStep();
        let prevStepIncrementCount = 1;
        while (!stepHeaders[currentStep - prevStepIncrementCount].enable) {
            prevStepIncrementCount++;
        }
        setCurrentStep((prev) => (prev > 0 ? prev - prevStepIncrementCount : prev));
    };

    const onNextStep = async () => {
        if (currentStep === stepHeaders.length - 1) {
            if (!selectedFile) return;
            if (!uploadTaskStatus.task) return;

            dispatch(
                BeneficiaryActionCreators.importRemoveEntitlements({
                    data: selectedFile,
                    schedule: true,
                    dryRun: false,
                    mapping,
                    startIndex: 2,
                    dryRunAsyncTaskId: uploadTaskStatus.task.id,
                    reason: selectedReason,
                })
            );
            dispatch(AsyncActionCreators.loadAsyncTasksList(1, 10, AsyncTasksTypes.UPLOAD_REMOVE_ENTITLEMENTS));
            onClose();
            return;
        }

        let nextStepIncrementCount = 1;
        while (!stepHeaders[currentStep + nextStepIncrementCount].enable) {
            nextStepIncrementCount++;
        }

        setCurrentStep((prev) => (prev < stepHeaders.length - 1 ? prev + nextStepIncrementCount : prev));
    };

    return (
        <div className={'wfp-stepper'}>
            <ModalBackground />
            <Modal.Dialog
                dialogClassName={'modal-medium'}
                style={{ overflow: 'auto', marginTop: '100px', paddingBottom: '150px' }}
            >
                <Modal.Header className={'align-items-center'}>
                    <span>File upload prerequisites</span>
                    <button className="close-button" onClick={onClose} type="button">
                        <ClearIcon />
                    </button>
                </Modal.Header>

                <Modal.Body>
                    <div className={'wfp-stepper__nav'}>
                        {stepHeaders
                            .filter((sh) => !sh.skip)
                            .map((sh, id) => {
                                return (
                                    <React.Fragment key={id}>
                                        <div
                                            className={classNames('wfp-stepper__nav__item mb-2', {
                                                active: currentStep === sh.id,
                                                disable: !sh.enable,
                                            })}
                                        >
                                            <div className={'item-id'}>{id + 1}</div>
                                            <div className={'item-label'}>{sh.label}</div>
                                        </div>
                                        {id < stepHeaders.length - 1 && <span className={'linker'} />}
                                    </React.Fragment>
                                );
                            })}
                    </div>

                    <div className={'wfp-stepper__steps'}>
                        {stepContents.map((sc) => {
                            return currentStep === sc.id ? (
                                <div className={'wfp-steps__step'} key={sc.id}>
                                    {sc.content}
                                </div>
                            ) : null;
                        })}
                    </div>
                </Modal.Body>

                <Modal.Footer className={'modal-footer'}>
                    <div>
                        <button className="wfp-btn" onClick={onClose} type="button">
                            Cancel
                        </button>
                        {currentStep === 1 && selectedFile && (
                            <button
                                className={classNames('wfp-btn--warning ml-2')}
                                onClick={() => {
                                    setSelectedFile(null);
                                    setUploadTaskStatus(new UploadTaskState(UploadStatuses.pending));
                                    setRefreshHandle(false);
                                }}
                                type="button"
                            >
                                Reset file
                            </button>
                        )}
                    </div>

                    <div>
                        {currentStep !== 0 && (
                            <button className="wfp-btn--primary" onClick={onPrevStep} type="button">
                                Go Back
                            </button>
                        )}
                        {stepHeaders.length - 1 === currentStep ? (
                            <button
                                className={classNames('wfp-btn--primary ml-2')}
                                disabled={shouldBlockRunImportButton(uploadTaskStatus)}
                                onClick={onNextStep}
                                type="button"
                            >
                                Run Import
                            </button>
                        ) : (
                            <button
                                className={classNames('wfp-btn--primary ml-2')}
                                disabled={isNextStepDisable}
                                onClick={onNextStep}
                                type="button"
                            >
                                {currentStep === 1 ? 'Yes' : 'Next'}
                            </button>
                        )}
                    </div>
                </Modal.Footer>
            </Modal.Dialog>
        </div>
    );
};

AssistanceRemovalStepper.displayName = 'AssistanceRemovalStepper';
