import { UaopFlags } from '../../DryRunDialog';
import { MetaCategories } from '../../../../country/types';

export const getRecommendedUaopFlagPerMetaCategory = (metaCategory: MetaCategories) => {
    switch (metaCategory) {
        case MetaCategories.NFI:
            return UaopFlags.IncrementalAnyAmount;
        default:
            return UaopFlags.IncrementalDeltaAmountYesOption;
    }
};
