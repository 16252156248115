import React, { FC } from 'react';

import { StepperProvider } from '../file-uploader-stepper/StepperContext';
import { AssistanceRemovalStepper } from './AssistanceRemovalStepper';
import { FileUploadProvider } from '../common/context/FileUploadContext';

export interface AssistanceRemovalStepperRootProps {
    onClose: () => void;
}

export const AssistanceRemovalStepperRoot: FC<AssistanceRemovalStepperRootProps> = ({ onClose }) => {
    return (
        <StepperProvider>
            <FileUploadProvider>
                <AssistanceRemovalStepper onClose={onClose} />
            </FileUploadProvider>
        </StepperProvider>
    );
};
