import React, { FC } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import State from '@wfp-root-app/store/state';

import { mapRemovalReasonNameToDisplayName } from '../utils';

export interface ReasonSelectStepProps {
    selectedReason: RemovalReason;
    setSelectedReason: (reason: RemovalReason) => void;
}

export enum RemovalReason {
    IncorrectUserId = 'IncorrectUserId',
    ReversalOfPayment = 'ReversalOfPayment',
    ChangeInHoHFinancialData = 'ChangeInHoHFinancialData',
    ErrorInOriginalIntendedAssistanceFileLoadedToBB = 'ErrorInOriginalIntendedAssistanceFileLoadedToBB',
    DistributionCancelled = 'DistributionCancelled',
    Other = 'Other',
}

export const ReasonSelectStep: FC<ReasonSelectStepProps> = ({ selectedReason, setSelectedReason }) => {
    const primaryIdentifierType = useSelector((state: State) => state.appConfig.beneficiaryPrimaryIdentifierType);

    return (
        <StyledDiv>
            <StyledHeading>
                What is the reason for the removal of this assistance (only one selection possible):
            </StyledHeading>
            {Object.keys(RemovalReason).map((reason) => (
                <StyledLabel key={reason}>
                    <input
                        checked={selectedReason === reason}
                        name={reason}
                        onChange={() => setSelectedReason(reason as RemovalReason)}
                        type="radio"
                    />{' '}
                    {mapRemovalReasonNameToDisplayName(reason as RemovalReason, primaryIdentifierType)}
                </StyledLabel>
            ))}
        </StyledDiv>
    );
};

const StyledHeading = styled('h6')`
    margin-bottom: 1rem;
`;

const StyledLabel = styled.label`
    font-weight: normal;
`;

const StyledDiv = styled.div`
    display: flex;
    flex-direction: column;
`;
