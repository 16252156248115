import React from 'react';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import State from '@wfp-root-app/store/state';
import BreadCrumbs from 'src/utils/BreadCrumbs';

import AsyncTasksListExportView from '../utils/AsyncTasksListExportView';
import { ActionCreators as BeneficiaryActionCreators } from '../beneficiaries/beneficiaries';
import { ActionCreators as AsyncActionCreators, AsyncTasksTypes, AsyncTask } from '../utils/asyncTasks';
import { PagedState } from '../utils/paging';
import { RefreshSignaler } from '../utils/refresher';
import {
    BeneficiaryPrimaryIdentifierToShow,
    BeneficiaryPrimaryIdentifierType,
    EntitlementCategoriesConfig,
} from '../app/appConfig';

interface Props {
    exports: PagedState<AsyncTask>;
    entitlementTotalCount?: number;
    downloadFile: (type: string, id: string) => string;
    loadAsyncTasksList: (page: number, limit: number, type: string) => Promise<RefreshSignaler>;
    exportEntitlements: (timezone: string, mapping: Array<{ key; header }>) => Promise<any>;
    authorizeTask: (type: string, id: string) => Promise<any>;
    appendAsyncTasksList: (limit: number, type: string) => void;
    managerId: string;
    categories: EntitlementCategoriesConfig;
    timezone: string;
    beneficiaryPrimaryIdentifierType: BeneficiaryPrimaryIdentifierType;
}

interface LocalState {
    selectedColumns: any;
    selectAll: boolean;
}

class TaxIdMappingExportPage extends React.Component<Props, LocalState> {
    private availableColumns = (identifierTypeToShow: string): Array<{ key: string; header: string }> => [
        { key: 'beneficiaryId', header: 'BB Account ID' },
        { key: 'taxId', header: `${identifierTypeToShow}` },
        { key: 'category', header: 'Category' },
        { key: 'nextUnassistedPeriodFrom', header: 'Unassisted from' },
        { key: 'nextUnassistedPeriodTo', header: 'Unassisted to' },
    ];

    private defaultPageSize = 10;
    private refresher?: RefreshSignaler;

    constructor(props: Props) {
        super(props);
        this.state = {
            selectedColumns: {
                id: true,
                householdId: true,
            },
            selectAll: false,
        };
    }

    async changePageRequested() {
        this.props.appendAsyncTasksList(this.defaultPageSize, AsyncTasksTypes.EXPORT_BENEFICIARIES_TAX_ID_MAPPING);
    }

    async UNSAFE_componentWillMount() {
        this.refresher = await this.props.loadAsyncTasksList(
            1,
            this.defaultPageSize,
            AsyncTasksTypes.EXPORT_BENEFICIARIES_TAX_ID_MAPPING
        );
    }

    componentWillUnmount() {
        if (this.refresher) {
            this.refresher.stop();
        }
    }

    renderExportFeature() {
        return (
            <div>
                <div className="wfp-form--actions">
                    <button className="wfp-btn" type="submit">
                        Generate
                    </button>
                </div>
            </div>
        );
    }

    renderExportFeatureDescription() {
        return (
            <div>
                <p>
                    Download Building Blocks Beneficiary Id to{' '}
                    {BeneficiaryPrimaryIdentifierToShow[this.props.beneficiaryPrimaryIdentifierType]} - BB ID Mapping
                    for your organization
                </p>
            </div>
        );
    }

    render() {
        const title = `Download ${
            BeneficiaryPrimaryIdentifierToShow[this.props.beneficiaryPrimaryIdentifierType]
        } - BB ID Mapping`;

        return (
            <main>
                <BreadCrumbs path={`/${title}`} />
                <h3>{title}</h3>
                {this.renderExportFeatureDescription()}
                <form onSubmit={this._exportEntitlements.bind(this)}>{this.renderExportFeature()}</form>
                {this.props.exports && this.props.exports.items.length > 0 && (
                    <div>
                        <AsyncTasksListExportView
                            authorize={this.props.authorizeTask.bind(this)}
                            authorizedManagerId={this.props.managerId}
                            canDownload={true}
                            data={this.props.exports.items}
                            downloadFile={this.props.downloadFile.bind(this)}
                        />
                        <div className="wfp-form--actions">
                            <button
                                className="wfp-btn--primary"
                                disabled={this.props.exports.items.length === this.props.exports.paging.total}
                                onClick={this.changePageRequested.bind(this)}
                                type="button"
                            >
                                More
                            </button>
                        </div>
                    </div>
                )}
            </main>
        );
    }

    _handleCheck(event) {
        const key = event.target.name;
        const value = event.target.checked;

        this.setState((prevState) => {
            const selectedColumns = prevState.selectedColumns;
            selectedColumns[key] = value;
            return { selectedColumns };
        });
    }

    _exportEntitlements(event) {
        event.preventDefault();
        const mapping = this.availableColumns(
            BeneficiaryPrimaryIdentifierToShow[this.props.beneficiaryPrimaryIdentifierType]
        );

        return this.props
            .exportEntitlements(this.props.timezone, mapping)
            .then(() =>
                this.props.loadAsyncTasksList(
                    1,
                    this.defaultPageSize,
                    AsyncTasksTypes.EXPORT_BENEFICIARIES_TAX_ID_MAPPING
                )
            );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        exportEntitlements: bindActionCreators(BeneficiaryActionCreators.exportTaxIdMapping, dispatch),
        loadAsyncTasksList: bindActionCreators(AsyncActionCreators.loadAsyncTasksList, dispatch),
        appendAsyncTasksList: bindActionCreators(AsyncActionCreators.appendAsyncTasksList, dispatch),
        authorizeTask: bindActionCreators(AsyncActionCreators.authorizeAsyncTask, dispatch),
        downloadFile: bindActionCreators(AsyncActionCreators.downloadFile, dispatch),
    };
}

function mapStateToProps(state: State) {
    return {
        exports: state.asyncTasks.asyncTasks[AsyncTasksTypes.EXPORT_BENEFICIARIES_TAX_ID_MAPPING] || new PagedState(),
        managerId: state.auth.manager ? state.auth.manager.id : null,
        categories: state.appConfig.entitlementsConfig,
        timezone: state.appConfig.timeZone,
        beneficiaryPrimaryIdentifierType: state.appConfig.beneficiaryPrimaryIdentifierType,
    };
}

export default connect(mapStateToProps, mapDispatchToProps, null)(TaxIdMappingExportPage as any);
