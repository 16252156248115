import * as React from 'react';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import State from '@wfp-root-app/store/state';

import { UaopFlags } from '../../DryRunDialog';
import { useStepperContext } from '../StepperContext';
import { CheckBoxContainer, Paragraph, isEnumValue } from '../utils/utils';
import { getTextByMetaCategoryAndFlag } from '../utils/uaopFlags';
import { getRecommendedUaopFlagPerMetaCategory } from '../utils/metaCategory';
import { MetaCategories } from '../../../../country/types';

interface DeduplicationOptionsStepProps {
    selectedCategory: string;
    selectedMetaCategory: MetaCategories;
    selectedFlag: UaopFlags;
    onSelectChange: (flag: UaopFlags) => void;
}

const DeduplicationOptionsStep: FC<DeduplicationOptionsStepProps> = (props) => {
    const { enableNextStep, disableNextStep } = useStepperContext();
    const { categoriesToDeduplicationFlags, categoryConfig } = useSelector(
        (state: State) => state.appConfig.entitlementsConfig
    );
    const currentCategoryConfig = useMemo(() => {
        return categoryConfig.find((category) => category.category === props.selectedCategory);
    }, [categoryConfig, props.selectedCategory]);
    const recommendedFlagForSelectedCategory = useMemo(() => {
        if (isEnumValue(UaopFlags, currentCategoryConfig?.recommendedFlagForDeduplication)) {
            return currentCategoryConfig.recommendedFlagForDeduplication;
        }

        return getRecommendedUaopFlagPerMetaCategory(props.selectedMetaCategory);
    }, [props.selectedMetaCategory]);

    const allowedDeduplicationFlags = useMemo<UaopFlags[]>(
        () => categoriesToDeduplicationFlags[props.selectedCategory],
        [props.selectedCategory, categoriesToDeduplicationFlags]
    );

    useEffect(() => {
        props.selectedFlag ? enableNextStep() : disableNextStep();
    }, [props.selectedFlag]);

    // TODO -> deprecated flags, so they are not supporting custom text per country/meta category
    const renderIncrementalDeltaAmountOption = useCallback(() => {
        return (
            <CheckBoxContainer>
                <label>
                    <input
                        checked={[
                            UaopFlags.IncrementalDeltaAmountNoOption,
                            UaopFlags.IncrementalDeltaAmountYesOption,
                        ].includes(props.selectedFlag)}
                        name="validate-category"
                        onChange={() => props.onSelectChange(UaopFlags.IncrementalDeltaAmountNoOption)}
                        type="radio"
                    />{' '}
                    Incremental: Delta Amount{' '}
                    {[UaopFlags.IncrementalDeltaAmountYesOption, UaopFlags.IncrementalDeltaAmountNoOption].includes(
                        recommendedFlagForSelectedCategory
                    )
                        ? '(Recommended)'
                        : ''}
                </label>
                <Paragraph>
                    Apply UAOP to my file, so if my assistance overlaps with any existing assistance during the
                    indicated assistance period in my file, only the difference between the amounts is loaded.
                    <br />
                    <br />
                    Furthermore (select one option):
                </Paragraph>

                <div className={'ml-4'}>
                    <CheckBoxContainer className={'mt-0 flex-row'}>
                        <label className={'d-flex align-items-center'}>
                            <input
                                checked={props.selectedFlag === UaopFlags.IncrementalDeltaAmountNoOption}
                                className={'mr-2'}
                                name="validate-category"
                                onChange={() => props.onSelectChange(UaopFlags.IncrementalDeltaAmountNoOption)}
                                type="checkbox"
                            />
                            <span className={'mr-2'}>Keep:</span>
                        </label>
                        <Paragraph>
                            If my assistance period overlaps with existing assistance, keep the overlap period(s). For
                            example, if I have indicated I want to assist a household with $6 from Mar-May, and it has
                            existing assistance of $3 from Jan-Mar, mark my assistance as $5 from Mar-May ($1 for Mar
                            and $2 each for Apr and May).
                        </Paragraph>
                    </CheckBoxContainer>
                    <CheckBoxContainer className={'mt-2 flex-row'}>
                        <label className={'d-flex align-items-center'}>
                            <input
                                checked={props.selectedFlag === UaopFlags.IncrementalDeltaAmountYesOption}
                                className={'mr-2'}
                                name="validate-category"
                                onChange={() => props.onSelectChange(UaopFlags.IncrementalDeltaAmountYesOption)}
                                type="checkbox"
                            />
                            <span className={'mr-2'}>Skip:</span>
                        </label>
                        <Paragraph>
                            If my assistance period overlaps with existing assistance, skip the overlap period(s). For
                            example, if I have indicated I want to assist a household with $6 from Mar-May, and it has
                            existing assistance of $3 from Jan-Mar, mark my assistance as $4 from Apr-May (and skip the
                            $2 for Mar).
                        </Paragraph>
                    </CheckBoxContainer>
                </div>
            </CheckBoxContainer>
        );
    }, [props.selectedFlag, recommendedFlagForSelectedCategory]);

    // TODO -> deprecated flag, so it is not supporting custom text per country/meta category
    const renderIncrementalShiftPeriod = useCallback(() => {
        return (
            <CheckBoxContainer>
                <label>
                    <input
                        checked={props.selectedFlag === UaopFlags.IncrementalShiftPeriod}
                        name="validate-category"
                        onChange={() => props.onSelectChange(UaopFlags.IncrementalShiftPeriod)}
                        type="radio"
                    />{' '}
                    Incremental: Shift Period
                </label>
                <Paragraph>
                    Apply UAOP to my file, so if my assistance overlaps with any existing assistance during the
                    indicated assistance period in my file, shift my assistance period to start the day after the
                    existing assistance period ends. For example, if I have indicated to assist a household with $6 from
                    Mar-May, and exiting assistance for that household is $3 from Jan-Mar, shift my assistance so that
                    it shows as $6 from Apr- Jun.
                    <br />
                    <br />
                    Note: To reduce uncertainty about yet to be available too far in the future, the system will shift
                    your assistance start date for a maximum of 1 month into the future from the start date in your
                    file. For example, if your loading assistance from Feb-Apr, and the exiting assistance is from
                    Jan-Mar, BB will not load your assistance as this would require a shift of 2 months (more than the
                    1-month max shift limit).
                    <br />
                    <br />
                    Note: If your assistance is shifted, you commit to ensuring your FSP makes the assistance available
                    to households as close as possible to the newly indicated start date.
                </Paragraph>
            </CheckBoxContainer>
        );
    }, [props.selectedFlag]);

    const renderIncrementalShiftPeriodV2 = useCallback(() => {
        return (
            <CheckBoxContainer>
                <label>
                    <input
                        checked={props.selectedFlag === UaopFlags.IncrementalShiftPeriodV2}
                        name="validate-category"
                        onChange={() => props.onSelectChange(UaopFlags.IncrementalShiftPeriodV2)}
                        type="radio"
                    />{' '}
                    Incremental: Shift Period
                </label>
                {getTextByMetaCategoryAndFlag(props.selectedMetaCategory, UaopFlags.IncrementalShiftPeriodV2)}
            </CheckBoxContainer>
        );
    }, [props.selectedFlag]);

    const renderIncrementalDeltaAmountV2 = useCallback(() => {
        return (
            <CheckBoxContainer>
                <label>
                    <input
                        checked={props.selectedFlag === UaopFlags.IncrementalDeltaAmountV2}
                        name="validate-category"
                        onChange={() => props.onSelectChange(UaopFlags.IncrementalDeltaAmountV2)}
                        type="radio"
                    />{' '}
                    Incremental: Delta Amount
                </label>
                {getTextByMetaCategoryAndFlag(props.selectedMetaCategory, UaopFlags.IncrementalDeltaAmountV2)}
            </CheckBoxContainer>
        );
    }, [props.selectedFlag]);

    const renderIncrementalAnyAmount = useCallback(() => {
        return (
            <CheckBoxContainer>
                <label>
                    <input
                        checked={props.selectedFlag === UaopFlags.IncrementalAnyAmount}
                        name="validate-category"
                        onChange={() => props.onSelectChange(UaopFlags.IncrementalAnyAmount)}
                        type="radio"
                    />{' '}
                    Incremental: Any Amount{' '}
                    {recommendedFlagForSelectedCategory === UaopFlags.IncrementalAnyAmount ? '(Recommended)' : ''}
                </label>
                {getTextByMetaCategoryAndFlag(props.selectedMetaCategory, UaopFlags.IncrementalAnyAmount)}
            </CheckBoxContainer>
        );
    }, [props.selectedFlag]);

    const renderIncrementalAnyAmountWithAllowedOverlap = useCallback(() => {
        return (
            <CheckBoxContainer>
                <label>
                    <input
                        checked={props.selectedFlag === UaopFlags.IncrementalAnyAmountWithAllowedOverlap}
                        name="validate-category"
                        onChange={() => props.onSelectChange(UaopFlags.IncrementalAnyAmountWithAllowedOverlap)}
                        type="radio"
                    />{' '}
                    Incremental: Intended Assistance Overlap{' '}
                    {recommendedFlagForSelectedCategory === UaopFlags.IncrementalAnyAmountWithAllowedOverlap
                        ? '(Recommended)'
                        : ''}
                </label>
                {getTextByMetaCategoryAndFlag(
                    props.selectedMetaCategory,
                    UaopFlags.IncrementalAnyAmountWithAllowedOverlap
                )}
            </CheckBoxContainer>
        );
    }, [props.selectedFlag]);

    const renderTotalAnyAmount = useCallback(() => {
        return (
            <CheckBoxContainer>
                <label>
                    <input
                        checked={props.selectedFlag === UaopFlags.TotalAnyAmount}
                        name="validate-category"
                        onChange={() => props.onSelectChange(UaopFlags.TotalAnyAmount)}
                        type="radio"
                    />{' '}
                    Total: Any Amount
                </label>
                {getTextByMetaCategoryAndFlag(props.selectedMetaCategory, UaopFlags.TotalAnyAmount)}
            </CheckBoxContainer>
        );
    }, [props.selectedFlag]);

    // TODO -> deprecated flag, so it is not supporting custom text per country/meta category
    const renderTotalDeltaAmount = useCallback(() => {
        return (
            <CheckBoxContainer>
                <label>
                    <input
                        checked={props.selectedFlag === UaopFlags.TotalDeltaAmount}
                        name="validate-category"
                        onChange={() => props.onSelectChange(UaopFlags.TotalDeltaAmount)}
                        type="radio"
                    />{' '}
                    Total: Delta Amount
                </label>
                <Paragraph>
                    Apply UAOP to my file, so if the sum of all existing assistance for each of the households in my
                    file for any amount and any period (past, present, or future) is equal to or more than my
                    assistance, my assistance is prevented. Otherwise, mark my assistance as the difference between what
                    is in my file and the sum of all existing assistance. For example, if I have indicated I want to
                    assist a household with $3 from Mar-May, and the sum of all existing assistance for that household
                    is $3, prevent my assistance. On the other hand, if I have indicated I want to assist a household
                    with $6 from Mar-May, and the sum of all existing assistance for that household is $3, mark my
                    assistance as $3 ($6 - $3) from Apr-Jun.
                </Paragraph>
            </CheckBoxContainer>
        );
    }, [props.selectedFlag]);

    const renderTotalDeltaAmountV2 = useCallback(() => {
        return (
            <CheckBoxContainer>
                <label>
                    <input
                        checked={props.selectedFlag === UaopFlags.TotalDeltaAmountV2}
                        name="validate-category"
                        onChange={() => props.onSelectChange(UaopFlags.TotalDeltaAmountV2)}
                        type="radio"
                    />{' '}
                    Total: Delta Amount
                </label>
                {getTextByMetaCategoryAndFlag(props.selectedMetaCategory, UaopFlags.TotalDeltaAmountV2)}
            </CheckBoxContainer>
        );
    }, [props.selectedFlag]);

    const renderNoneHistorical = useCallback(() => {
        return (
            <CheckBoxContainer>
                <label>
                    <input
                        checked={props.selectedFlag === UaopFlags.NoneHistorical}
                        name="validate-category"
                        onChange={() => props.onSelectChange(UaopFlags.NoneHistorical)}
                        type="radio"
                    />{' '}
                    None (Historical)
                </label>
                {getTextByMetaCategoryAndFlag(props.selectedMetaCategory, UaopFlags.NoneHistorical)}
            </CheckBoxContainer>
        );
    }, [props.selectedFlag]);

    const renderNoneIntended = useCallback(() => {
        return (
            <CheckBoxContainer>
                <label>
                    <input
                        checked={props.selectedFlag === UaopFlags.NoneIntendedAssistanceOverlap}
                        name="validate-category"
                        onChange={() => props.onSelectChange(UaopFlags.NoneIntendedAssistanceOverlap)}
                        type="radio"
                    />{' '}
                    None (Intended Assistance Overlap)
                </label>
                {getTextByMetaCategoryAndFlag(props.selectedMetaCategory, UaopFlags.NoneIntendedAssistanceOverlap)}
            </CheckBoxContainer>
        );
    }, [props.selectedFlag]);

    const mappedFlagToRenderFunction = useMemo(() => {
        return {
            [UaopFlags.IncrementalAnyAmount]: renderIncrementalAnyAmount,
            [UaopFlags.IncrementalDeltaAmountV2]: renderIncrementalDeltaAmountV2,
            [UaopFlags.IncrementalDeltaAmountYesOption]: renderIncrementalDeltaAmountOption,
            [UaopFlags.IncrementalDeltaAmountNoOption]: renderIncrementalDeltaAmountOption,
            [UaopFlags.IncrementalShiftPeriod]: renderIncrementalShiftPeriod,
            [UaopFlags.IncrementalShiftPeriodV2]: renderIncrementalShiftPeriodV2,
            [UaopFlags.TotalAnyAmount]: renderTotalAnyAmount,
            [UaopFlags.TotalDeltaAmount]: renderTotalDeltaAmount,
            [UaopFlags.TotalDeltaAmountV2]: renderTotalDeltaAmountV2,
            [UaopFlags.NoneHistorical]: renderNoneHistorical,
            [UaopFlags.NoneIntendedAssistanceOverlap]: renderNoneIntended,
            [UaopFlags.IncrementalAnyAmountWithAllowedOverlap]: renderIncrementalAnyAmountWithAllowedOverlap,
        };
    }, [
        renderIncrementalAnyAmount,
        renderIncrementalDeltaAmountV2,
        renderIncrementalDeltaAmountOption,
        renderIncrementalShiftPeriod,
        renderIncrementalShiftPeriodV2,
        renderTotalAnyAmount,
        renderTotalDeltaAmount,
        renderTotalDeltaAmountV2,
        renderNoneHistorical,
        renderNoneIntended,
    ]);

    const optionsToBeRendered = useMemo(() => {
        const uniqueRenderOptions = new Set<() => JSX.Element>();

        allowedDeduplicationFlags.forEach((flag) => {
            const renderFunction = mappedFlagToRenderFunction[flag];

            if (!renderFunction) {
                return;
            }

            if (
                [UaopFlags.IncrementalDeltaAmountYesOption, UaopFlags.IncrementalDeltaAmountNoOption].includes(flag) &&
                allowedDeduplicationFlags.includes(UaopFlags.IncrementalDeltaAmountYesOption) &&
                allowedDeduplicationFlags.includes(UaopFlags.IncrementalDeltaAmountNoOption)
            ) {
                uniqueRenderOptions.add(renderFunction);
            } else {
                uniqueRenderOptions.add(renderFunction);
            }
        });

        // we have to use Set and then convert to array because of duplicates in case of Yes/No option in delta amount
        return Array.from(uniqueRenderOptions) || [];
    }, [mappedFlagToRenderFunction]);

    return (
        <>
            <h6
                style={{
                    marginTop: '32px',
                }}
            >
                What type of UAOP would you like to perform (only one selection possible):
            </h6>
            <Paragraph>
                Note: for each UAOP option, the assistance period must be the same for all the rows in your file.
            </Paragraph>
            {optionsToBeRendered.map((renderFunc) => renderFunc())}
        </>
    );
};

DeduplicationOptionsStep.displayName = 'DeduplicationOptionsStep';

export { DeduplicationOptionsStep };
