import { UploadStatuses } from '../../../utils/import';
import { UploadTaskState } from '../../beneficiaries';

export const shouldBlockRunImportButton = (uploadTaskStatus: UploadTaskState) => {
    if (uploadTaskStatus.status !== UploadStatuses.finished) {
        return true;
    }
    if (!uploadTaskStatus.task) {
        return true;
    } else if (uploadTaskStatus.error) {
        return true;
    } else if (uploadTaskStatus.task && uploadTaskStatus.task.errors && uploadTaskStatus.task.errors.length > 0) {
        return true;
    }
    return false;
};
