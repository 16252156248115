import { bindActionCreators } from 'redux';
import React, { lazy } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import LoadingBar from 'react-redux-loading-bar';
import ReduxBlockUi from 'react-block-ui/redux';
import { HIDE as StopLoadingAction, SHOW as StartLoadingAction } from 'react-redux-loading-bar/build/loading_bar_ducks';

import 'react-block-ui/style.css';
import 'rc-switch/assets/index.css';

import { routerHelperActions } from '@wfp-common/store/routerHelperSlice';
import State from '@wfp-root-app/store/state';
import Logo from '@wfp-common/components/logo/Logo';

import { ActionCreators as authActionCreators } from '../login/auth';
import { closeNotificationAction, Notification } from '../utils/notifications';
import SessionProlongAlert from '../utils/sessionExpiration/sessionProlongAlert';
import Manager, { hasManagersManagementFeature, hasPartnersFeature } from '../managers/manager';
import { PendingActionsState } from '../utils/pedingActionsReducer';
import { supStyle } from '../utils/utils';
import { projectTypeNaming } from './appConfig';
import { BENEFICIARY_SUPPORT_PANEL_URL } from '../partners/utils/constants';
import { getCurrentCountry } from '../utils/countries';

declare function require(string): string;

const country = getCurrentCountry();
const LogoCountrySpecific = lazy(
    () => import(`../images/${country === 'default' ? 'ukraine' : `${country}`}_logo.svg`)
);

class AppContainer extends React.Component<PropsFromRedux, any> {
    constructor(props) {
        super(props);
        this.state = {};
    }

    UNSAFE_componentWillMount() {
        this.setState({});
        this.props.reloadUserData();
    }

    UNSAFE_componentWillReceiveProps() {
        if (this.props.auth.manager && (!this.props.pendingActions || this.props.pendingActions.usersPost === null)) {
            this.props.loadPendingActions();
        }
    }

    createNavLinks() {
        if (!this.props.auth.apiToken || !this.props.auth.manager) {
            return null;
        }
        const pendingActions: PendingActionsState = this.props.pendingActions;

        const hideBeneficiarySupportTab = this.props.hideBeneficiarySupportTab;
        const manager = this.props.auth.manager as Manager;
        const partnersTabPendingCounter = pendingActions.partnersPost;
        const partnersTab = (
            <div>
                Beneficiary Support{' '}
                {partnersTabPendingCounter > 0 ? <sup style={supStyle}>{partnersTabPendingCounter}</sup> : null}
            </div>
        );
        const adminTabPendingCounter =
            pendingActions.usersPost +
            pendingActions.partnersPost +
            pendingActions.partnerUsersPost +
            pendingActions.permissionProfilePost;
        const adminTab = (
            <div>Admin {adminTabPendingCounter > 0 ? <sup style={supStyle}>{adminTabPendingCounter}</sup> : null}</div>
        );
        const adjustmentsTabPendingCounter = pendingActions.adjustments
            ? Object.values(pendingActions.adjustments).reduce((acc, val) => acc + val, 0)
            : null;

        const adjustmentsTab = (
            <div>
                Requests{' '}
                {adjustmentsTabPendingCounter > 0 ? <sup style={supStyle}>{adjustmentsTabPendingCounter}</sup> : null}
            </div>
        );
        return (
            !manager.askForNewPassword && (
                <div>
                    <ul
                        className="navbar nav"
                        style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: 0, paddingBottom: 0 }}
                    >
                        {hasManagersManagementFeature(manager) && (
                            <li className="myNavbarItem">
                                <Link to="/adjustments/users">{adjustmentsTab}</Link>
                            </li>
                        )}
                        {hasManagersManagementFeature(manager) && (
                            <li className="myNavbarItem">
                                <Link to="/admin">{adminTab}</Link>
                            </li>
                        )}
                        <li className="myNavbarItem">
                            <Link to="/documents">
                                <div>
                                    Documents
                                    {!!pendingActions.documentsUpload && (
                                        <sup style={supStyle}>{pendingActions.documentsUpload}</sup>
                                    )}
                                </div>
                            </Link>
                        </li>
                        <li className="myNavbarItem">
                            <Link to="/entitlements/import">
                                <div>File Upload</div>
                            </Link>
                        </li>
                        <li className="myNavbarItem">
                            <Link to="/entitlements/download">
                                <div>File Download</div>
                            </Link>
                        </li>
                        {!hideBeneficiarySupportTab && hasPartnersFeature(manager) && (
                            <li className="myNavbarItem">
                                <Link to={BENEFICIARY_SUPPORT_PANEL_URL}>{partnersTab}</Link>
                            </li>
                        )}
                    </ul>
                </div>
            )
        );
    }

    renderUserName() {
        const manager = this.props.auth.manager as Manager;
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const profileImage = require('../styles/profile-icon.png');
        const hasManagerManagementPermission = manager && hasManagersManagementFeature(manager);

        if (manager) {
            return (
                <div
                    style={{
                        justifyContent: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        cursor: hasManagerManagementPermission ? 'pointer' : 'default',
                        gridArea: 'user',
                    }}
                >
                    <a
                        onClick={hasManagerManagementPermission && this.props.linkToPersonal}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            alignSelf: 'center',
                            cursor: hasManagerManagementPermission ? 'pointer' : 'default',
                        }}
                    >
                        <img
                            alt={'profile-image'}
                            className="visible-sm visible-md visible-lg"
                            src={profileImage}
                            style={{ height: 35, width: 35 }}
                        />
                        <label
                            style={{
                                fontSize: 12,
                                textAlign: 'center',
                                color: 'white',
                            }}
                        >
                            {manager.firstName} {manager.lastName}
                        </label>
                        <label
                            style={{
                                fontSize: 8,
                                textAlign: 'center',
                                color: 'white',
                            }}
                        >
                            {projectTypeNaming[this.props.project]}
                        </label>
                    </a>
                    <a
                        onClick={this.props.logout}
                        style={{
                            fontSize: 12,
                            color: 'white',
                            border: 'none',
                            alignSelf: 'center',
                        }}
                    >
                        Log Out
                    </a>
                </div>
            );
        }
    }

    renderHeader() {
        const navLinks: JSX.Element = this.createNavLinks();

        return (
            <header className="wfp-header-ext">
                <nav
                    className="wfp-wrapper"
                    role="banner"
                    style={{
                        display: 'grid',
                        minWidth: '100%',
                        margin: 0,
                        gridTemplateAreas: "'logo title user' 'logo navLinks user'",
                        alignItems: 'center',
                        padding: '0 25px',
                    }}
                >
                    <Logo linkTo={'home'} />
                    <h4>Inter-Organizational Unintended Assistance Overlap Prevention</h4>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            gridArea: 'navLinks',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        {navLinks}
                    </div>
                    {this.renderUserName()}
                </nav>
            </header>
        );
    }

    render() {
        const shouldPresentSessionProlongAlert = this.props.sessionExpiration.prolongSessionAlertPresented;

        return (
            <div>
                {this.renderHeader()}
                <LoadingBar />
                {this.props.notification && (
                    <Notification close={this.props.closeNotification} of={this.props.notification} />
                )}
                {shouldPresentSessionProlongAlert && (
                    <SessionProlongAlert
                        onClose={this.props.closeSessionProlongAlert}
                        onProlongAlert={this.props.prolongSession}
                    />
                )}
                <ReduxBlockUi
                    block={StartLoadingAction}
                    className="wfp-wrapper pv3"
                    tag="div"
                    unblock={StopLoadingAction}
                >
                    <Outlet />
                </ReduxBlockUi>
            </div>
        );
    }
}

function mapStateToProps(state: State) {
    return {
        project: state.appConfig.project,
        auth: state.auth,
        notification: state.notification,
        sessionExpiration: state.sessionExpiration,
        pendingActions: state.pendingActions,
        hideBeneficiarySupportTab: state.appConfig.hideBeneficiarySupportTab,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        logout: () => authActionCreators.logout()(dispatch),
        loadPendingActions: bindActionCreators(authActionCreators.getPendingActions, dispatch),
        prolongSession: bindActionCreators(authActionCreators.prolongSession, dispatch),
        closeNotification: () => dispatch(closeNotificationAction),
        closeSessionProlongAlert: bindActionCreators(authActionCreators.endSession, dispatch),
        reloadUserData: bindActionCreators(authActionCreators.reloadUserData, dispatch),
        linkToPersonal: () => dispatch(routerHelperActions.makeRedirect('/personal/details')),
    };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
const ConnectedProps = connector(AppContainer);

export { ConnectedProps as AppContainer };
