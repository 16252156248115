import styled from 'styled-components';
import React from 'react';

import { UaopFlags } from '../../DryRunDialog';
import { exhaustiveCheck } from '../../../../utils/utils';
import { getCurrentCountry } from '../../../../utils/countries';
import { confirmationTextMapping } from '../../../../country/confirmationTextMapping';
import { ConfirmationsModel } from './types';

export const ModalBackground = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1050;
    background-color: rgba(0, 0, 0, 0.4);
`;

export const Paragraph = styled.p`
    font-weight: lighter;
    font-size: 14px;
    font-style: italic;
`;

export const CheckBoxContainer = styled.div<{ marginTop?: string }>`
    display: flex;
    flex-direction: column;
    margin-top: ${(props) => props.marginTop || '25px'};
`;

export const CONFIRMATIONS: ConfirmationsModel = {
    allDetailsCorrect: {
        name: 'allDetailsCorrect',
        content: (identifierAbbreviation: string, supportEmail: string) => (
            <h6>All the details stated here are correct.</h6>
        ),
    },
    uaopUserManual: {
        name: 'uaopUserManual',
        content: (identifierAbbreviation: string, supportEmail: string) => (
            <>
                You have thoroughly read and understood the latest version of the UAOP user manual available in the
                “Documents” section.
            </>
        ),
    },
    individualLevelAssistance: {
        name: 'individualLevelAssistance',

        content: (identifierAbbreviation: string, supportEmail: string) => (
            <>
                Assistance in your file is at the individual household member level. This means you have only included
                the targeted individual household member {identifierAbbreviation}s in your assistance file.
            </>
        ),
    },
    householdEnterpriseFocalPointMapping: {
        name: 'householdEnterpriseFocalPointMapping',
        content: (identifierAbbreviation: string, supportEmail: string) => (
            <>
                If you wish to avail of the enterprise (household) focal point (HF) to the enterprise (household) member
                (HM) TID mapping feature to deduplicate assistance for co-owners, you have already loaded the latest
                version of your mapping according to your organization using the newest template available in the
                “Documents” section.
            </>
        ),
    },
    householdFocalPointMapping: {
        name: 'householdFocalPointMapping',
        content: (identifierAbbreviation: string, supportEmail: string) => (
            <>
                If you wish to avail of the head of household to the household member {identifierAbbreviation} mapping
                feature, you have already loaded the latest version of your mapping according to your organization using
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                the newest template available in the "Documents" section.
            </>
        ),
    },
    assistanceFileAsPerTemplate: {
        name: 'assistanceFileAsPerTemplate',
        content: (identifierAbbreviation: string, supportEmail: string) => (
            <>You have prepared your assistance file using the latest template available in the “Documents” section.</>
        ),
    },
    onlyHouseholdFocalPointTIDIncluded: {
        name: 'onlyHouseholdFocalPointTIDIncluded',
        content: (identifierAbbreviation: string, supportEmail: string) => (
            <>
                Assistance in your file is at the head of household {identifierAbbreviation} level on behalf of the
                entire household (and not the individual household member level). This means you have only included head
                of household {identifierAbbreviation}s in your assistance file.
            </>
        ),
    },
    onlyHouseholdEnterpriseFocalPointTIDIncluded: {
        name: 'onlyHouseholdEnterpriseFocalPointTIDIncluded',
        content: (identifierAbbreviation: string, supportEmail: string) => (
            <>
                Assistance in your file is at the enterprise (household) focal point {identifierAbbreviation} level on
                behalf of the entire enterprise (and not the individual enterprise member level). This means you have
                only included enterprise focal point {identifierAbbreviation}s in your assistance file.
            </>
        ),
    },
    assistancePeriodCorrect: {
        name: 'assistancePeriodCorrect',
        content: (identifierAbbreviation: string, supportEmail: string) => (
            <>The assistance period(s) in your file is/are correct.</>
        ),
    },
    multipliedAssistanceAmountPerPersonPerMonth: {
        name: 'multipliedAssistanceAmountPerPersonPerMonth',
        content: (identifierAbbreviation: string, supportEmail: string) => (
            <>The assistance amount stated is the total amount for the assistance period selected.</>
        ),
    },
    clarifiedQuestionsWithBBTeam: {
        name: 'clarifiedQuestionsWithBBTeam',
        content: (identifierAbbreviation: string, supportEmail: string) => (
            <>You have clarified any questions you may have with the BB team ({supportEmail}).</>
        ),
    },
    whenHistoricalSelected: {
        name: 'whenHistoricalSelected',
        content: (identifierAbbreviation: string, supportEmail: string) => (
            <>
                You are loading your assistance file as close as possible to the time when you will make the assistance
                available to the households.
            </>
        ),
    },
    whenIncrementalShiftPeriod: {
        name: 'whenIncrementalShiftPeriod',
        content: (identifierAbbreviation: string, supportEmail: string) => {
            const shiftConfirmation =
                confirmationTextMapping?.whenIncrementalShiftPeriod?.customPerCountry[getCurrentCountry()];

            return (
                shiftConfirmation || (
                    <>
                        If your assistance period start date is shifted to the future (possible with the “Incremental
                        (Shift Period)” UAOP option, commit to ensuring your FSP makes the assistance available to
                        households as close as possible to the newly indicated start date. Note: for this UAOP option,
                        the assistance period must be the same for all the rows in your file.
                    </>
                )
            );
        },
    },
    bePatient: {
        name: 'bePatient',
        content: (identifierAbbreviation: string, supportEmail: string) => (
            <>
                If your file is not executed immediately, you will kindly be patient and wait for it to start executing,
                and you will not load the same file again just because a previous file you have loaded has not started
                executing yet.
            </>
        ),
    },
};

export const commonConfirmations = (isEnterpriseCategory: boolean) => {
    return [
        CONFIRMATIONS.allDetailsCorrect,
        CONFIRMATIONS.uaopUserManual,
        isEnterpriseCategory
            ? CONFIRMATIONS.householdEnterpriseFocalPointMapping
            : CONFIRMATIONS.householdFocalPointMapping,
        CONFIRMATIONS.assistanceFileAsPerTemplate,
        isEnterpriseCategory
            ? CONFIRMATIONS.onlyHouseholdEnterpriseFocalPointTIDIncluded
            : CONFIRMATIONS.onlyHouseholdFocalPointTIDIncluded,
        CONFIRMATIONS.assistancePeriodCorrect,
        CONFIRMATIONS.multipliedAssistanceAmountPerPersonPerMonth,
        CONFIRMATIONS.clarifiedQuestionsWithBBTeam,
        CONFIRMATIONS.bePatient,
    ];
};

export const commonHouseholdLevelWinterCategoryConfirmations = [
    CONFIRMATIONS.allDetailsCorrect,
    CONFIRMATIONS.uaopUserManual,
    CONFIRMATIONS.householdFocalPointMapping,
    CONFIRMATIONS.assistanceFileAsPerTemplate,
    CONFIRMATIONS.onlyHouseholdFocalPointTIDIncluded,
    CONFIRMATIONS.assistancePeriodCorrect,
    CONFIRMATIONS.clarifiedQuestionsWithBBTeam,
    CONFIRMATIONS.bePatient,
];

export const commonIndividualLevelWinterCategoryConfirmations = [
    CONFIRMATIONS.allDetailsCorrect,
    CONFIRMATIONS.uaopUserManual,
    CONFIRMATIONS.assistanceFileAsPerTemplate,
    CONFIRMATIONS.individualLevelAssistance,
    CONFIRMATIONS.assistancePeriodCorrect,
    CONFIRMATIONS.clarifiedQuestionsWithBBTeam,
    CONFIRMATIONS.bePatient,
];

export type HouseholdExclusionType = 'NONE' | 'ONE_MONTH' | 'TWO_MONTHS' | 'THREE_MONTHS';

export type HouseholdExclusionModel = {
    [P in HouseholdExclusionType]: {
        id: P;
        label: JSX.Element;
        value: Readonly<string | number>;
        disabled?: boolean;
        visibleName: Readonly<string>;
    };
};

export const HouseholdExclusionModelImpl: HouseholdExclusionModel = {
    NONE: {
        id: 'NONE',
        label: <span className={'font-weight-bold'}>No.</span>,
        value: 'none',
        visibleName: 'none',
    },
    ONE_MONTH: {
        id: 'ONE_MONTH',
        label: (
            <>
                <span className={'font-weight-bold'}>One Month: </span> Yes, exclude households with existing assistance
                in the selected categories anytime during the 1 month before my assistance start date.
            </>
        ),
        value: 1,
        visibleName: '1 month',
    },
    TWO_MONTHS: {
        id: 'TWO_MONTHS',
        label: (
            <>
                <span className={'font-weight-bold'}>Two Months: </span> Yes, exclude households with existing
                assistance in the selected categories anytime during the 2 months before my assistance start date.
            </>
        ),
        value: 2,
        visibleName: '2 months',
    },
    THREE_MONTHS: {
        id: 'THREE_MONTHS',
        label: (
            <>
                {' '}
                <span className={'font-weight-bold'}>Three Months: </span>Yes, exclude households with existing
                assistance in the selected categories anytime during the 3 months before my assistance start date.
            </>
        ),
        value: 3,
        visibleName: '3 months',
    },
} as const;

export const mapStrategyFlag = (flag: UaopFlags) => {
    switch (flag) {
        case UaopFlags.NoneHistorical:
        case UaopFlags.IncrementalShiftPeriod:
        case UaopFlags.IncrementalDeltaAmountYesOption:
        case UaopFlags.IncrementalAnyAmount:
        case UaopFlags.IncrementalDeltaAmountNoOption:
        case UaopFlags.TotalAnyAmount:
        case UaopFlags.TotalDeltaAmount:
        case UaopFlags.NoneIntendedAssistanceOverlap:
            return flag.replace(/[A-Z]/g, ' $&').trim();
        case UaopFlags.TotalDeltaAmountV2:
        case UaopFlags.IncrementalDeltaAmountV2:
        case UaopFlags.IncrementalShiftPeriodV2:
            return flag.replace(/[A-Z]/g, ' $&').trim().replace('V2', '');
        case UaopFlags.IncrementalAnyAmountWithAllowedOverlap:
            return 'Incremental: Intended Assistance Overlap';
        default:
            exhaustiveCheck(flag);
    }
};

export const isEnumValue = <T extends object>(enumObject: T, value: unknown): value is T[keyof T] => {
    return Object.values(enumObject).includes(value as T[keyof T]);
};
