import Action from '../action';
import { BeneficiaryPrimaryIdentifierToShow } from '@wfp-root-app/appConfig';

export enum AssistanceListType {
    AssistanceProvided = 'Assistance Provided',
    AssistanceDeduplicated = 'Assistance Prevented',
    BBIdMapping = 'BB ID Mapping',
    CaseloadOverlap = 'Caseload Overlap',
}

export const AssistanceListLabels = (beneficiaryIdentifier: string) => {
    return {
        [AssistanceListType.AssistanceProvided]: 'Assistance Provided',
        [AssistanceListType.AssistanceDeduplicated]: 'Assistance Prevented',
        [AssistanceListType.CaseloadOverlap]: 'Caseload Overlap',
        [AssistanceListType.BBIdMapping]: `${BeneficiaryPrimaryIdentifierToShow[beneficiaryIdentifier]} - BB ID Mapping`,
    };
};

export class AssistanceState {
    activeTab: AssistanceListType;

    constructor(tab: AssistanceListType) {
        this.activeTab = tab;
    }
}

export const ActionTypes = {
    changeTab: 'AssistantPage.changeTab',
};

export const ActionCreators = {
    changeTab(tab: AssistanceListType) {
        return (dispatch) =>
            dispatch({
                type: ActionTypes.changeTab,
                payload: tab,
            });
    },
};

export function assistanceReducer(
    state: AssistanceState = new AssistanceState(AssistanceListType.AssistanceProvided),
    action: Action
) {
    switch (action.type) {
        case ActionTypes.changeTab:
            return new AssistanceState(action.payload);
    }
    return state;
}
