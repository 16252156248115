import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import State from '@wfp-root-app/store/state';

import { errorAction, successAction } from '../../utils/notifications';
import { triggerBeneficiariesIdsDownloadFromStats } from '../../apiClient';
import { RowMenu } from '../../utils/RowMenu';
import { currencyFormatter, displayTime } from '../../utils/utils';
import {
    BeneficiaryPrimaryIdentifierToShow,
    BeneficiaryPrimaryIdentifierType,
    EntitlementCategoriesConfig,
    PredefinedCurrency,
} from '../../app/appConfig';
import BigNumber from '../../utils/bigNumber';
import { useStatisticsHelper } from './useStatisticsHelper';
import {
    DuplicationPreventedByAgencyResponse,
    DuplicationPreventedThanksToAgencyDataResponse,
    TaxIdsOverlapStatsInterface,
    TaxIdsOverlapStatsResponse,
    UniqueTaxIdsStatsInterface,
    UniqueTaxIdsStatsResponse,
} from './deduplication-statistics.model';
import { AuthState } from '../../login/auth';

interface DeduplicationStatisticsProps {
    auth: Pick<AuthState, 'manager'>;
}

export function DeduplicationStatistics({ auth }: DeduplicationStatisticsProps) {
    const navigate = useNavigate();
    const intl = new Intl.NumberFormat(navigator.language);
    const dispatch = useDispatch();

    const [isGeneratingFile, setIsGeneratingFile] = useState(false);

    const beneficiaryPrimaryIdentifierType = useSelector(
        (state: State) => state.appConfig.beneficiaryPrimaryIdentifierType
    );

    const { statsState, ...statsHelper } = useStatisticsHelper();

    const handleBeneficiariesIdsDownload = async (overlapRecord: string, category: string) => {
        try {
            if (isGeneratingFile) {
                dispatch(errorAction('Another file is currently being generated. Please wait.'));
                return;
            }

            const result = await triggerBeneficiariesIdsDownloadFromStats(overlapRecord, category);
            setIsGeneratingFile(true);

            if (result) {
                dispatch(successAction('Generation of file with beneficiaries ids has been triggered.'));
                setIsGeneratingFile(false);
                navigate(`/tasks/export-beneficiaries-from-stats/${result.asyncTaskId}`);
            }
        } catch (error) {
            dispatch(
                errorAction(
                    'An error occurred while trying to generate file with beneficiaries. Please try again later.'
                )
            );
            setIsGeneratingFile(false);
        }
    };

    const { currencyConfig, entitlementsCategoryConfig } = useSelector((state: State) => ({
        entitlementsCategoryConfig: state.appConfig.entitlementsConfig,
        currencyConfig: state.appConfig.entitlementCurrencyConfig,
    }));

    const [activeCategory, setActiveCategory] = useState<string>(null);

    const agency = useMemo(() => {
        return auth?.manager?.agency;
    }, [auth]);

    function renderRowMenu() {
        return (
            entitlementsCategoryConfig && (
                <div>
                    <RowMenu
                        activeTab={activeCategory}
                        onSelectTab={(tab) => {
                            setActiveCategory(tab);
                            statsHelper.fetchStatisticsByCategory(tab);
                        }}
                        tabs={
                            entitlementsCategoryConfig.categories.length > 1
                                ? [...entitlementsCategoryConfig.categories, 'ALL']
                                : entitlementsCategoryConfig.categories
                        }
                    />
                </div>
            )
        );
    }

    function renderStats() {
        const deduplicationStatsInfo = entitlementsCategoryConfig.categoryConfig.find(
            (cc) => cc?.category === activeCategory
        )?.deduplicationStatsInfo;
        const deduplicationInfo = deduplicationStatsInfo?.length > 0 ? deduplicationStatsInfo : 'Duplication prevented';

        return (
            currencyConfig &&
            activeCategory &&
            entitlementsCategoryConfig && (
                <div>
                    <table className="wfp-table mt4 table-hover wfp-caseload-table">
                        <tbody>
                            <tr>
                                <th>Organization</th>
                                <th>Value</th>
                                <th>Note</th>
                            </tr>
                            {agency === 'ALL' &&
                                renderUniqueStats(
                                    statsState.uniqueTaxIdsStatistics,
                                    intl,
                                    beneficiaryPrimaryIdentifierType
                                )}
                            {agency === 'ALL' &&
                                renderDuplicationPreventedByAgencyStatistics(
                                    statsState.duplicationPreventedByAgencyStatistics,
                                    intl,
                                    currencyConfig,
                                    deduplicationInfo
                                )}
                            {agency === 'ALL' &&
                                renderDuplicationPreventedThanksToAgencyDataStatistics(
                                    statsState.duplicationPreventedThanksToAgencyDataStatistics,
                                    intl,
                                    currencyConfig,
                                    deduplicationInfo
                                )}
                            {renderTaxIdOverlapStats(
                                activeCategory,
                                statsState.taxIdsOverlapStats,
                                statsState.uniqueTaxIdsStatistics,
                                intl,
                                agency,
                                handleBeneficiariesIdsDownload,
                                entitlementsCategoryConfig,
                                beneficiaryPrimaryIdentifierType
                            )}
                        </tbody>
                    </table>
                    <br />
                    <br />

                    {statsState.uniqueTaxIdsStatistics && (
                        <div className="row">
                            <div className="col-sm-12">
                                <small>
                                    <i>Stats calculated as of {displayTime(statsState.uniqueTaxIdsStatistics.time)}</i>
                                </small>
                            </div>
                        </div>
                    )}
                </div>
            )
        );
    }

    if (statsState.isLoading) {
        return (
            <div className={'text-center p-5'}>
                <div className={'statistic-loader p-5'}>Loading statistics...</div>
            </div>
        );
    }

    return (
        <div>
            <br />
            {renderRowMenu()}
            {statsState.hasStatsToDisplay && renderStats()}

            {!statsState.hasStatsToDisplay && statsState.areStatsExist && (
                <div>
                    <br />
                    <br />
                    <div className="row">
                        <div className="col-sm-12">
                            <small>
                                <i> No stats for selected category as of {displayTime(new Date())}</i>
                            </small>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

function renderUniqueStats(
    uniqueTaxIdStats: UniqueTaxIdsStatsResponse,
    intl,
    beneficiaryPrimaryIdentifierType: BeneficiaryPrimaryIdentifierType
) {
    if (uniqueTaxIdStats) {
        return (
            <>
                {uniqueTaxIdStats.stats.map((uniqueTaxIdStats: UniqueTaxIdsStatsInterface) => {
                    return (
                        <tr key={uniqueTaxIdStats.count + uniqueTaxIdStats.agency}>
                            <td>{uniqueTaxIdStats.agency}</td>
                            <td>{intl.format(uniqueTaxIdStats.count)}</td>
                            <td>
                                Unique HoH {BeneficiaryPrimaryIdentifierToShow[beneficiaryPrimaryIdentifierType]}s in{' '}
                                {uniqueTaxIdStats.agency} load file(s)
                            </td>
                        </tr>
                    );
                })}
                <tr>
                    <td>TOTAL</td>
                    <td>{intl.format(uniqueTaxIdStats.total.count)}</td>
                    <td>
                        Unique HoH {BeneficiaryPrimaryIdentifierToShow[beneficiaryPrimaryIdentifierType]}s in all load
                        files
                    </td>
                </tr>
            </>
        );
    }
}

function renderTaxIdOverlapStats(
    category: string,
    taxIdOverlap: TaxIdsOverlapStatsResponse,
    uniqueTaxIdStats: UniqueTaxIdsStatsResponse,
    intl,
    agency: string,
    handleBeneficiariesIdsDownload: (overlapRecord: string, category) => void,
    entitlementsCategoryConfig: EntitlementCategoriesConfig,
    beneficiaryPrimaryIdentifierType: BeneficiaryPrimaryIdentifierType
) {
    if (taxIdOverlap && uniqueTaxIdStats) {
        return (
            <>
                {taxIdOverlap.stats.map((taxIdOverlap: TaxIdsOverlapStatsInterface) => {
                    const agencies = taxIdOverlap.agencies.split('-');
                    const hasOnClickHandler = Number(taxIdOverlap.count) > 0;

                    if (agencies.length > 1) {
                        return (
                            <tr key={taxIdOverlap.count + taxIdOverlap.agencies}>
                                <td>{taxIdOverlap.agencies}</td>
                                <td>{intl.format(taxIdOverlap.count)}</td>
                                <td>
                                    <div>
                                        Unique HoH{' '}
                                        {BeneficiaryPrimaryIdentifierToShow[beneficiaryPrimaryIdentifierType]} overlap
                                        <br />
                                        {agencies
                                            .filter((agencyElem) => {
                                                return agency === 'ALL' || (agency !== 'ALL' && agencyElem === agency);
                                            })
                                            .map((agencyElem: string) => {
                                                const taxIdStatsForAgency = uniqueTaxIdStats.stats.find(
                                                    (stats) => stats.agency === agencyElem
                                                );
                                                if (taxIdStatsForAgency) {
                                                    const overlapPercent = new BigNumber(taxIdOverlap.count)
                                                        .dividedBy(taxIdStatsForAgency.count)
                                                        .multipliedBy(100)
                                                        .toFixed(2);

                                                    return agency === 'ALL' ? (
                                                        <div key={agencyElem}>
                                                            {taxIdStatsForAgency.agency} ({overlapPercent}%)
                                                        </div>
                                                    ) : (
                                                        <div key={agencyElem}>{overlapPercent}% </div>
                                                    );
                                                }
                                            })}
                                    </div>

                                    {hasOnClickHandler && (
                                        <button
                                            className="wfp-btn"
                                            onClick={() => {
                                                let queryCategories = category;

                                                if (category === 'ALL') {
                                                    queryCategories = entitlementsCategoryConfig.categories.join(',');
                                                }

                                                handleBeneficiariesIdsDownload(taxIdOverlap.agencies, queryCategories);
                                            }}
                                            type="button"
                                        >
                                            Download
                                        </button>
                                    )}
                                </td>
                            </tr>
                        );
                    }
                })}
            </>
        );
    }
}

function renderDuplicationPreventedByAgencyStatistics(
    duplicationPreventedByAgencyStatistics: DuplicationPreventedByAgencyResponse,
    intl,
    currencyConfig: PredefinedCurrency,
    deduplicationInfo: string
) {
    if (duplicationPreventedByAgencyStatistics) {
        return (
            <>
                {duplicationPreventedByAgencyStatistics.stats.map((duplicationPreventedByAgency) => {
                    return (
                        <tr key={duplicationPreventedByAgency.sum + duplicationPreventedByAgency.agency + '1'}>
                            <td>{duplicationPreventedByAgency.agency}</td>
                            <td>
                                {currencyFormatter(currencyConfig).format(
                                    new BigNumber(duplicationPreventedByAgency.sum).toNumber()
                                )}
                            </td>
                            <td>
                                {deduplicationInfo} by {duplicationPreventedByAgency.agency} thanks to other
                                organizations’ data
                            </td>
                        </tr>
                    );
                })}
                <>
                    <tr>
                        <td>TOTAL</td>
                        <td>
                            {currencyFormatter(currencyConfig).format(
                                renderTotalSum(duplicationPreventedByAgencyStatistics)
                            )}
                        </td>
                        <td> {deduplicationInfo} by all organizations</td>
                    </tr>
                    {currencyConfig.exchangeRates && currencyConfig.exchangeRates['USD'] && (
                        <tr>
                            <td>USD EQV</td>
                            <td>
                                {currencyFormatter({ name: 'USD', fractionDigits: 2 }).format(
                                    new BigNumber(renderTotalSum(duplicationPreventedByAgencyStatistics))
                                        .dividedBy(currencyConfig.exchangeRates['USD'])
                                        .toNumber()
                                )}
                            </td>
                            <td>
                                Equivalent amount of {deduplicationInfo.toLowerCase()} by all organizations @
                                {currencyConfig.exchangeRates['USD']} {currencyConfig.name}/USD
                            </td>
                        </tr>
                    )}
                </>
            </>
        );
    }
}

function renderDuplicationPreventedThanksToAgencyDataStatistics(
    duplicationPreventedThanksToAgencyDataStatistics: DuplicationPreventedThanksToAgencyDataResponse,
    intl,
    currencyConfig: PredefinedCurrency,
    deduplicationInfo: string
) {
    if (duplicationPreventedThanksToAgencyDataStatistics) {
        return (
            <>
                {duplicationPreventedThanksToAgencyDataStatistics.stats.map(
                    (duplicationPreventedThanksToAgencyData) => {
                        return (
                            <tr
                                key={
                                    duplicationPreventedThanksToAgencyData.sum +
                                    duplicationPreventedThanksToAgencyData.agency +
                                    '1'
                                }
                            >
                                <td>{duplicationPreventedThanksToAgencyData.agency}</td>
                                <td>
                                    {currencyFormatter(currencyConfig).format(
                                        new BigNumber(duplicationPreventedThanksToAgencyData.sum).toNumber()
                                    )}
                                </td>
                                <td>
                                    {deduplicationInfo} by other organizations thanks to{' '}
                                    {duplicationPreventedThanksToAgencyData.agency}&apos;s data
                                </td>
                            </tr>
                        );
                    }
                )}
                <>
                    <tr>
                        <td>TOTAL</td>
                        <td>
                            {currencyFormatter(currencyConfig).format(
                                renderTotalSum(duplicationPreventedThanksToAgencyDataStatistics)
                            )}
                        </td>
                        <td> {deduplicationInfo} by all organizations</td>
                    </tr>
                    {currencyConfig.exchangeRates && currencyConfig.exchangeRates['USD'] && (
                        <tr>
                            <td>USD EQV</td>
                            <td>
                                {currencyFormatter({ name: 'USD', fractionDigits: 2 }).format(
                                    new BigNumber(renderTotalSum(duplicationPreventedThanksToAgencyDataStatistics))
                                        .dividedBy(currencyConfig.exchangeRates['USD'])
                                        .toNumber()
                                )}
                            </td>
                            <td>
                                Equivalent amount of {deduplicationInfo.toLowerCase()} by all organizations @
                                {currencyConfig.exchangeRates['USD']} {currencyConfig.name}/USD
                            </td>
                        </tr>
                    )}
                </>
            </>
        );
    }
}

function renderTotalSum(
    duplicationStats: DuplicationPreventedByAgencyResponse | DuplicationPreventedThanksToAgencyDataResponse
) {
    let total = new BigNumber(0);
    duplicationStats.stats.forEach((stat) => (total = total.plus(stat.sum)));
    return total.toNumber();
}
